import React, { useEffect, useRef, useState } from "react";
import "./Header.scss";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/slices/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import {
  categoriesAsync,
  mainCategoryAsync,
  selectCategories,
  selectMainCategories,
  setSearchQuery,
} from "../../redux/slices/productSlice";
import { fetchCartsAsync, selectCart } from "../../redux/slices/cartSlice";
import { quickSearch } from "../../redux/api/searchApi";
import { QuickSearchResult } from "../../interfaces/quickSearch";
import { useClickOutside } from "../../hooks/useClickOutside";
import { setSelectedMyVehicle, setVin } from "../../redux/slices/garageSlice";
import SelectedVehicle from "../selectedVehicle/SelectedVehicle";
import Product from "../../pages/product/Product";
import ProductBlock from "../weekDeal/ProductBlock";

export default function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const cart = useSelector(selectCart);
  const user = useSelector(selectUser);
  const mainCategories = useSelector(selectMainCategories);
  const categories = useSelector(selectCategories);
  const dispatch = useAppDispatch();
  const [search, setSearch] = React.useState("");
  const [searchResult, setSearchResult] = React.useState<QuickSearchResult>(
    {} as QuickSearchResult,
  );
  const [timer, setTimer] = React.useState<any>(null);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setTimeout(() => {
      setOpen(false);
    }, 500);
  });

  useEffect(() => {
    dispatch(mainCategoryAsync());
    dispatch(categoriesAsync());
    if (user?.user) {
      dispatch(fetchCartsAsync());
    }
  }, [dispatch]);

  const mainCategoriesIcon: any = {
    "FREN SİSTEMİ": "icon-tair",
    SÜSPANSİYON: "icon-shock-absorber",
    "KAPORTA/TRİM": "icon-car-insurance",
    MOTOR: "icon-repair",
    ELEKTRİK: "icon-repair",
    "OTOBAKIM ÜRÜNLERİ": "icon-repair",
    ŞANZIMAN: "icon-car-insurance",
    YAĞLAR: "icon-repair",
    FİLTRELER: "icon-repair",
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const search = (e.target as any).search.value;
    console.log(search);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value.length < 4) {
      return;
    }
    setOpen(true);
    timer && clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        try {
          quickSearch(e.target.value).then((res) => {
            if (!res) {
              return;
            }
            setSearchResult(res);
          });
        } catch (ignore) {}
      }, 500),
    );
  };

  const handleEnterSearch = () => {
    dispatch(setSearchQuery(search));
    if (searchResult.products && searchResult.products.length > 0) {
      navigate("/vehicle-product");
      setSearch("");
    }
    if (searchResult.brands && searchResult.brands.length > 0) {
      navigate("/search-products-brand");
      setSearch("");
    }
    if (searchResult.vins && searchResult.vins.length > 0) {
      dispatch(setVin(searchResult.vins[0]));
      navigate("/search-products-chassis");
      setSearch("");
    }
    if (searchResult.myVehicles && searchResult.myVehicles.length > 0) {
      dispatch(setSelectedMyVehicle(searchResult.myVehicles[0]));
      navigate("/vehicle-product");
      setSearch("");
    }
    
  };

  const renderSearchBox = () => {
    return (
      <div ref={wrapperRef} id="search-box" className="search-box">
        <form onSubmit={handleSearch}>
          <input
            type="search"
            placeholder="sonparca.com'da arayın"
            name="search"
            value={search}
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && search.length > 3) {
                handleEnterSearch();
              }
            }}
          />
          <button onClick={handleEnterSearch} type="submit">
            <i className="icon-magnifing-glass"></i>
          </button>
        </form>
        {open &&
          searchResult &&
          (searchResult.brands?.length > 0 ||
            searchResult.myVehicles?.length > 0 ||
            searchResult.products?.length > 0 ||
            searchResult.vins?.length > 0) && (
            <div className="search-result">
              {searchResult?.products && searchResult?.products.length > 0 && (
                <div className="search-result-item-title ">Ürünler</div>
              )}

              {searchResult?.products?.map((product) => (
                <div
                  className="search-result-item"
                  key={product.id}
                  onClick={() => {
                    setSearch("");
                    navigate(`/product/${product.url}`);
                    setOpen(false);
                  }}
                >
                  <div className="product-search-result">
                    {product.imageUrls?.[0] && (
                      <div className="product-search-result-image">
                        <img
                          src={product.imageUrls?.[0]}
                          alt={product.name}
                          width="50"
                          height="50"
                        />
                      </div>
                    )}

                    <div className="product-search-result-info">
                      <div className="product-search-result-name">
                        {product.name}
                      </div>
                      <div className="product-search-result-brand">
                        {product.brand.name}
                      </div>
                      <div className="product-search-result-price">
                        {product.price.toFixed(2)} TL
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {searchResult?.brands && searchResult?.brands.length > 0 && (
                <div className="search-result-item-title ">Markalar</div>
              )}
              {searchResult?.brands?.map((brand) => (
                <div className="search-result-item" key={brand.id}>
                  {brand.name}
                </div>
              ))}
              {searchResult?.vins && searchResult?.vins.length > 0 && (
                <div className="search-result-item-title ">VIN</div>
              )}
              {searchResult?.vins?.map((vin) => (
                <div
                  className="search-result-item"
                  key={vin}
                  onClick={() => {
                    dispatch(setVin(vin));
                    navigate(`/search-products-chassis`);
                    setSearch("");
                    setOpen(false);
                  }}
                >
                  {vin}
                </div>
              ))}
              {searchResult?.myVehicles &&
                searchResult?.myVehicles.length > 0 && (
                  <div className="search-result-item-title ">Araçlar</div>
                )}
              {searchResult?.myVehicles?.map((vehicle) => (
                <div
                  className="search-result-item"
                  key={vehicle.id}
                  onClick={() => {
                    dispatch(setSelectedMyVehicle(vehicle));
                    setSearch("");
                    navigate("/vehicle-product");
                  }}
                >
                  {<SelectedVehicle myVehicle={vehicle} />}
                </div>
              ))}
            </div>
          )}
      </div>
    );
  };

  return (
    <header className="main-header ex_shop_header header-style-one">
      <div
        id="menupop"
        className="menupop"
        style={{ display: menuOpen ? "block" : "none" }}
        onClick={(e) => {
          ((e.target as any).className === "menupop" ||
            (e.target as any).tagName === "A") &&
            setMenuOpen(false);
        }}
      >
        <div className="menupop-container">
          <div className="menupop-header">
            <div className="menupop-close" onClick={() => setMenuOpen(false)}>
              <i className="flaticon-add"></i>
            </div>
          </div>

          <div className="menupop-sidebar">
            <ul className="sidebar-dropdown">
              <li>
                <h6>Ana Menü</h6>
              </li>
              <li>
                <NavLink to="/search-products-chassis">
                  Şaseden Parça Bul{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/vehicle-product">Araçtan Parça Bul </NavLink>
              </li>
              <li>
                <NavLink to="/maintain-robot">Bakım Robotu</NavLink>
              </li>
            </ul>
            <ul className="sidebar-dropdown menupop-dropdown">
              <li>
                <h6>Kategoriler</h6>
              </li>
              <>
                {mainCategories.map((mc) => (
                  <li key={mc.url}>
                    <Link to={`categories/${mc.url}`}>
                      <i
                        className={
                          mainCategoriesIcon[mc.name] || "icon-scooter"
                        }
                      ></i>
                      {mc.name}
                    </Link>
                    <ul className="dropdown">
                      {categories
                        .filter((c) => c.parentId === mc.id)
                        .map((c) => (
                          <li className="dropdown" key={c.url}>
                            <Link to={`categories/${c.url}`}>{c.name}</Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
              </>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-top">
        <div className="auto-container">
          <div className="wrapper-box">
            <div className="left-column">
              <ul className="contact-info">
                <li>
                  <Link to="/about">Hakkımızda </Link>
                </li>
                <li>
                  <Link to="/account">Hesabım</Link>
                </li>
                <li>
                  <Link to="/account/orders">Sipariş Takibi</Link>
                </li>
                <li>
                  <Link to="/wishlist">İstek Listesi</Link>
                </li>
              </ul>
            </div>
            <div className="right-column">
              <div className="help-info">
                Yardım için <span> bizi ara: </span>{" "}
                <Link to="tel:+08508403400" className="help-info-number">
                  {" "}
                  (+0850) 840 34 00
                </Link>{" "}
                <span> veya </span>{" "}
                <Link to="mailto:info@company.com">
                  {" "}
                  destek@sonparca.com.tr
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-upper">
        <div className="auto-container">
          <div className="inner-container">
            <div className="logo_menu">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/">
                    <img
                      src="/assets/images/parca-dunyasi-icon.png"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="menu-area clearfix">
                <nav className="main-menu clearfix navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix home-menu">
                      <li>
                        <NavLink to="/search-products-chassis">
                          Şaseden Parça Bul
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/vehicle-product">
                          Araçtan Parça Bul{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/maintain-robot">Bakım Robotu</NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <ul className="right-info">
              <li>
                <NavLink to={"/cart"} className="shopping-cart">
                  <i className="icon-shopping-cart"></i>
                  <span className="count">{cart.items.length}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/cart"} className="shopping-cart-info">
                  <div className="shopping-cart-info">
                    {" "}
                    <h5>SEPETİM</h5>{" "}
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header-lower">
        <div className="auto-container">
          <div className="outer-box">
            <div className="menu-area clearfix">
              <div className="shop-category">
                <Link to={"#"} onClick={() => setMenuOpen(true)}>
                  <span className="flaticon-menu"></span>Kategoriler
                </Link>
              </div>
              <div className="mobile-nav-toggler">
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
              </div>
            </div>
            {renderSearchBox()}
            <div className="navbar-right-info">
              <ul className="right-info">
                <li className="favorite">
                  <div className="shopping-cart">
                    <i className="icon-heart"></i>
                    <span className="count">0</span>
                  </div>
                </li>
                <li className="search-toggler">
                  <i className="icon-left-right-arrow"></i>
                </li>
                <li>
                  <Link to="/account">
                    <>
                      <i className="icon-user"></i>{" "}
                      <p>
                        Hesabım{" "}
                        <span>
                          {user?.user
                            ? `Selam, ${user.user.firstName}`
                            : "Giriş Yap"}
                        </span>
                      </p>
                    </>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-search-box-wrapper">{renderSearchBox()}</div>

      <div className="sticky-header">
        <div className="auto-container">
          <div className="outer-box">
            <div className="logo-box">
              <div className="logo">
                <Link to="/">
                  <img src="/assets/images/parca-dunyasi-icon.png" alt="logo" />
                </Link>
              </div>
            </div>
            <div className="menu-area clearfix">
              <nav className="main-menu clearfix"></nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
