import React from "react";
import { Link } from "react-router-dom";

interface ContentOneProps {
  category: string;
  title: string;
  date?: string;
  commentsCount?: number;
  image: string;
  text1: string;
  text2: string;
}

const ContentOne: React.FC<ContentOneProps> = ({
  category,
  title,
  date,
  commentsCount,
  image,
  text1,
  text2,
}) => {
  return (
    <div className="content-one p_relative d_block mb_30">
      <div className="post-title p_relative d_block mb_30">
        <div className="category p_relative d_block mb_7">
          <Link
            to="blog.html"
            className="d_iblock fs_16 font_family_poppins uppercase"
          >
            {category}
          </Link>
        </div>
        <h2 className="d_block fs_40 lh_50 fw_bold mb_7">{title}</h2>
        <ul className="post-info clearfix p_relative d_block">
          {date && <li className="p_relative d_iblock mr_30 fs_16">{date}</li>}
          {commentsCount && (
            <li className="p_relative d_iblock fs_16">
              {commentsCount} Comments
            </li>
          )}
        </ul>
      </div>
      <figure className="image-box p_relative d_block b_radius_5 mb_65">
        <img src={image} alt="blog" />
      </figure>
      <div className="text">
        <p className="font_family_poppins mb_25">{text1}</p>
        <p className="font_family_poppins">{text2}</p>
      </div>
    </div>
  );
};

export default ContentOne;
