// vehicleApi.ts
import { VehicleSelections } from "../../interfaces/vehicle";
import { fetchService, serverUrl } from "./fetchService";

// Function to fetch vehicle brands
export async function fetchBrands() {
  return await fetchService({
    url: `${serverUrl}/Vehicles/getBrands`,
    method: "GET",
  });
}

// Function to fetch models for a specific brand
export async function fetchModels(brandId: number) {
  return await fetchService({
    url: `${serverUrl}/Vehicles/getModels?brandId=${brandId}`,
    method: "GET",
  });
}

// Function to fetch years for a specific brand and model
export async function fetchYears(brandId: number, modelId: number) {
  const years = await fetchService({
    url: `${serverUrl}/Vehicles/getYears?brandId=${brandId}&modelId=${modelId}`,
    method: "GET",
  });
  return years.map((y: number) => ({ id: y, year: y }));
}

// Function to fetch fuel types for a specific brand, model, and year
export async function fetchFuels(
  brandId: number,
  modelId: number,
  year: number,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/getFuels?brandId=${brandId}&modelId=${modelId}&year=${year}`,
    method: "GET",
  });
  if (response) {
    return response.map((f: string) => ({ id: f, type: f }));
  }
  return response;
}

// Function to fetch engine options based on brand, model, year, and fuel type
export async function fetchEngines(
  brandId: number,
  modelId: number,
  year: number,
  fuel: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/getEngines?brandId=${brandId}&modelId=${modelId}&year=${year}&fuel=${fuel}`,
    method: "GET",
  });
  if (response) {
    return response.map((e: string) => ({ id: e, type: e }));
  }
  return response;
}

// Additional functions for gears, gearboxes, horsepower, body types, etc.
export async function fetchGears(
  brandId: number,
  modelId: number,
  year: number,
  fuel: string,
  engine: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/getGears?brandId=${brandId}&modelId=${modelId}&year=${year}&fuel=${fuel}&engine=${engine}`,
    method: "GET",
  });
  if (response) {
    return response.map((g: string) => ({ id: g, type: g }));
  }
  return response;
}

export async function fetchGearBox(
  brandId: number,
  modelId: number,
  year: number,
  fuel: string,
  engine: string,
  gear: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/getGearBox?brandId=${brandId}&modelId=${modelId}&year=${year}&fuel=${fuel}&engine=${engine}&gear=${gear}`,
    method: "GET",
  });
  if (response) {
    return response.map((g: string) => ({ id: g, type: g }));
  }
  return response;
}

export async function fetchHorsepower(
  brandId: number,
  modelId: number,
  year: number,
  fuel: string,
  engine: string,
  gear: string,
  gearBox: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/getHorsepowerKw?brandId=${brandId}&modelId=${modelId}&year=${year}&fuel=${fuel}&engine=${engine}&gear=${gear}&gearBox=${gearBox}`,
    method: "GET",
  });
  if (response) {
    return response.map((h: string) => ({
      id: h,
      horsepower: h,
    }));
  }
  return response;
}

export async function fetchBodyType(
  brandId: number,
  modelId: number,
  year: number,
  fuel: string,
  engine: string,
  gear: string,
  gearBox: string,
  horsepowerKw: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/getBodyType?brandId=${brandId}&modelId=${modelId}&year=${year}&fuel=${fuel}&engine=${engine}&gear=${gear}&gearBox=${gearBox}&horsepowerKw=${horsepowerKw}`,
    method: "GET",
  });
  if (response) {
    return response.map((b: string) => ({ id: b, type: b }));
  }
  return response;
}

export async function findPartsFromVehicle(
  brandId: number,
  modelId: number,
  year: number,
  fuel: string,
  engine: string,
  gear: string,
  gearBox: string,
  horsepowerKw: string,
  bodyType: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/findVehicleBySpecs?brandId=${brandId}&modelId=${modelId}&year=${year}&fuel=${fuel}&engine=${engine}&gear=${gear}&gearBox=${gearBox}&horsepowerKw=${horsepowerKw}&bodyType=${bodyType}`,
    method: "GET",
  });
  return response;
}

export async function findByVehicleIdentificationId(
  vehicleIdentificationId: string,
) {
  const response = await fetchService({
    url: `${serverUrl}/Vehicles/findByVehicleIdentificationNumber?vehicleIdentificationNumber=${vehicleIdentificationId}`,
    method: "GET",
    throwErrors: false,
  });
  return response;
}

// Add a vehicle to the user's profile
export async function addVehicle(vehicleDetails: VehicleSelections) {
  return await fetchService({
    url: `${serverUrl}/MyVehicle/addMyVehicle`,
    method: "POST",
    body: vehicleDetails,
  });
}

export async function getCompatibleVehicles(productId: number) {
  return await fetchService({
    url: `${serverUrl}/Vehicles/getCompatibleVehicles?productId=${productId}`,
    method: "GET",
  });
}
