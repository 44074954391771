import React from 'react';
import { Link } from 'react-router-dom';

interface NavigationButtonProps {
    navigation: {
        prev: {
            link: string;
            text: string;
        };
        next: {
            link: string;
            text: string;
        };
    };
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ navigation }) => {
    return (
        <div className="nav-btn p_relative d_block mb_70">
            <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 btn-column">
                    <div className="single-btn prev-btn p_relative d_block b_radius_5 pt_25 pr_30 pb_25 pl_30 tran_5">
                        <h6 className="d_block fs_15 fw_sbold mb_11"><Link to={navigation.prev.link} className="d_iblock color_black"><i className="far fa-long-arrow-left"></i>Previous</Link></h6>
                        <h5 className="d_block fs_17 lh_24 fw_sbold">{navigation.prev.text}</h5>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 btn-column">
                    <div className="single-btn next-btn text-right p_relative d_block b_radius_5 pt_25 pr_30 pb_25 pl_30 tran_5">
                        <h6 className="d_block fs_15 fw_sbold mb_11"><Link to={navigation.next.link} className="d_iblock color_black">Next<i className="far fa-long-arrow-right"></i></Link></h6>
                        <h5 className="d_block fs_17 lh_24 fw_sbold">{navigation.next.text}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigationButton;
