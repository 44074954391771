import React from "react";
import "./CategoryBlock.scss";
import { Link } from "react-router-dom";
import { ICategory } from "../../interfaces/product";

interface CategoriesProps {
  categories: ICategory[];
}

const CategoryBlock = (props: CategoriesProps) => {
  const { categories } = props;
  return (
    <section className="block-categories-wrapper">
      <div className="block-categories">
        <div className="auto-container">
          <div className="common-section-title">
            <div className="section-title">
              <img
                src="/assets/images/icons/featured-nav-left.png"
                alt="icon"
              />
              <h4>Kategoriler</h4>
            </div>
          </div>
          <div className="block-categories__body">
            <div className="block-categories__list">
              {categories.map((category, index) => (
                <div key={index} className="block-categories__item">
                  <div className="category-card">
                    <div className="category-card__body">
                      <div className="category-card__content">
                        <div className="category-card__image">
                          <a className="image__body" href={category.url}>
                            <img
                              alt={category.name}
                              className="image__tag"
                              src={`/assets/images/categories/category-${category.id}.jpg`}
                            />
                          </a>
                        </div>
                        <div className="category-card__info">
                          <div className="category-card__name">
                            <Link to={`categories/${category.url}`}>
                              {category.name}
                            </Link>
                          </div>
                          <ul className="category-card__children">
                            {category.subCategories.map((sub, subIndex) => (
                              <li key={subIndex}>
                                <Link to={`categories/${sub.url}`}>
                                  {sub.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                          <div className="category-card__actions">
                            <Link
                              className="category-card__link"
                              to={`categories/${category.url}`}
                            >
                              Tümünü Göster
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryBlock;
