import React from "react";
import AuthorBox from "./AuthorBox";
import BlogPost, { BlogPostProps } from "./BlogPost";
import CommentsForm from "./CommentsForm";
import NavigationButton from "./NavigationButton";
import PostShareOption from "./PostShareOption";

interface MainBlogDetailsComponentProps extends BlogPostProps {
  author?: {
    name: string;
    image: string;
    description: string;
  };
  navigation?: {
    prev: {
      link: string;
      text: string;
    };
    next: {
      link: string;
      text: string;
    };
  };
  tags?: string[];
  socialLinks?: {
    platform: string;
    link: string;
    iconClass: string;
  }[];
  showCommentsForm?: boolean;
  showPostShareOption?: boolean;
}

const MainBlogDetailsComponent: React.FC<MainBlogDetailsComponentProps> = (
  props: MainBlogDetailsComponentProps,
) => {
  const { author, navigation, tags, socialLinks, showCommentsForm, showPostShareOption } = props;
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 content-side">
      <div className="blog-details-content p_relative d_block mr_20">
        <BlogPost {...props} />
        {showPostShareOption && tags && socialLinks && <PostShareOption tags={tags} socialLinks={socialLinks} />}
        {navigation && <NavigationButton navigation={navigation} />}
        {author && <AuthorBox author={author} />}
        {showCommentsForm && <CommentsForm />}
      </div>
    </div>
  );
};

export default MainBlogDetailsComponent;
