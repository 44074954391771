import React, { useEffect } from "react";
import "./AddAdress.scss";
import { IAddress } from "../../interfaces/address";
import { fetchCityList, fetchDistrictList, fetchNeighborhoodList } from "../../redux/api/addressApi";

interface IAddAddressProps {
  address: IAddress;
  setAddress: (address: IAddress) => void;
  showError: boolean;
}

export default function AddAddress(props: IAddAddressProps) {
  const {showError} = props;
  const [cityList, setCityList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [neighborhoodList, setNeighborhoodList] = React.useState([]);

  useEffect(() => {
    fetchCityList().then((res) => {
      if (!res) return;
      setCityList(res);
      setDistrictList([]);
      setNeighborhoodList([]);
    });
  }, []);

  const selectedCityId = props.address.cityId;
  useEffect(() => {
    if (selectedCityId === undefined) {
      return
    }
    fetchDistrictList(selectedCityId).then((res) => {
      if (!res) return;
      setDistrictList(res);
    });
  }, [selectedCityId]);
  const selectedDistrictId = props.address.districtId;
  const selectedNeighborhoodId = props.address.neighborhoodId;
  useEffect(() => {
    if (selectedDistrictId === undefined) {
      return
    }
    fetchNeighborhoodList(selectedDistrictId).then((res) => {
      if (!res) return;
      setNeighborhoodList(res);
    });
  }, [selectedCityId, selectedDistrictId]);
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    props.setAddress({
      ...props.address,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckBoxChange = (targetName: string, checked?: boolean) => {
    props.setAddress({
      ...props.address,
      [targetName]: checked,
    });
  };

  return (
    <>
      <div className="add-address row">
      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Adres Adı*
          </label>
          <div className="field-input">
            <input
              className="form-control"
              value={props.address.addressName}
              onChange={handleChange}
              type="text"
              name="addressName"
            />
            {
              showError && !props.address.addressName && <span className="error">Adres Adı boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Ad*
          </label>
          <div className="field-input">
            <input
              className="form-control"
              value={props.address.name}
              onChange={handleChange}
              type="text"
              name="name"
            />
            {
              showError && !props.address.name && <span className="error">Ad boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Soyad*
          </label>
          <div className="field-input">
            <input
              className="form-control"
              type="text"
              name="surname"
              value={props.address.surname}
              onChange={handleChange}
            />
            {
              showError && !props.address.surname && <span className="error">Soyad boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Email*
          </label>
          <div className="field-input">
            <input
              className="form-control"
              type="email"
              name="email"
              value={props.address.email}
              onChange={handleChange}
            />
            {
              showError && !props.address.email && <span className="error">Email boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Telefon*
          </label>
          <div className="field-input">
            <input
              className="form-control"
              type="phone"
              name="phone"
              value={props.address.phone}
              onChange={handleChange}
            />
            {
              showError && !props.address.phone && <span className="error">Telefon boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Şehir*
          </label>
          <div className="field-input">
            <select className="form-control" name="cityId" onChange={handleChange}>
              <option value="">Şehir Seçiniz</option>
              {cityList?.map((city: any) => (
                <option key={city.id} value={city.id} selected={city.id === props.address.cityId}>
                  {city.name}
                </option>
              ))}
            </select>
            {
              showError && !props.address.cityId && <span className="error">Şehir boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            İlçe*
          </label>
          <div className="field-input">
            <select
              className="form-control"
              name="districtId"
              disabled={!props.address.cityId || !districtList.length}
              onChange={handleChange}
            >
              <option value="">İlçe Seçiniz</option>
              {districtList.map((district: any) => (
                <option key={district.id} value={district.id} selected={district.id === props.address.districtId}>
                  {district.name}
                </option>
              ))}
            </select>
            {
              showError && !props.address.districtId && <span className="error">İlçe boş bırakılamaz</span>
            }
          </div>
        </div>
        {
        // neighborhood
        }
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Mahalle*
          </label>
          <div className="field-input">
            <select
              className="form-control"
              name="neighborhoodId"
              disabled={!props.address.cityId || !props.address.districtId || !neighborhoodList.length}
              onChange={handleChange}
            >
              <option value="">Mahalle Seçiniz</option>
              {neighborhoodList.map((neighborhood: any) => (
                <option key={neighborhood.id} value={neighborhood.id} selected={neighborhood.id === props.address.neighborhoodId}>
                  {neighborhood.name}
                </option>
              ))}
            </select>
            {
              showError && !props.address.neighborhoodId && <span className="error">Mahalle boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
          <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
            Address*
          </label>
          <div className="field-input">
            <textarea
              className="form-control"
              name="addressText"
              value={props.address.addressText}
              onChange={handleChange}
            />
            {
              showError && !props.address.addressText && <span className="error">Adres boş bırakılamaz</span>
            }
          </div>
        </div>
        <div className="form-group col-lg-6 col-md-6 col-sm-12">
          <div className="create-acc p_relative d_block mt_3">
            <div className="check-box">
              <input
                className="check form-control"
                type="checkbox"
                id="default"
                checked={props.address.default}
                onChange={(e) =>
                  handleCheckBoxChange("default", !props.address.default)
                }
              />
              <label htmlFor="default" onClick={() => handleCheckBoxChange("default", !props.address.default)}>Varsayılan Adres</label>
            </div>
          </div>
        </div>
        <div className="form-group col-lg-12 col-md-12 col-sm-12">
          <div className="create-acc p_relative d_block mt_3">
            <div className="check-box">
              <input
                className="check"
                type="checkbox"
                id="isInvoice"
                checked={props.address.isInvoice}
                onChange={(e) =>
                  handleCheckBoxChange("isInvoice", !props.address.isInvoice)
                }
              />
              <label className="fs_16 fw_medium font_family_inter color_black" htmlFor="isInvoice" onClick={
                (e) =>
                  handleCheckBoxChange("isInvoice", !props.address.isInvoice)
                }>Fatura Adresi Olarak Kaydet</label>
            </div>
          </div>
        </div>
      </div>
      {props.address.isInvoice && (
        <>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
                Fatura Tipi
              </label>
              <div className="field-input">
                <select
                  className="form-control"
                  name="invoiceType"
                  onChange={handleChange}
                >
                  <option value={"0"}>Bireysel</option>
                  <option value={"1"}>Kurumsal</option>
                </select>
                {
                  showError && !props.address.invoiceType && <span className="error">Fatura Tipi boş bırakılamaz</span>
                }
              </div>
            </div>
          </div>
          {props.address.invoiceType + "" === "1" && (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
                  Firma Ünvanı*
                </label>
                <div className="field-input">
                  <input
                    className="form-control"
                    type="text"
                    name="invoiceTitle"
                    onChange={handleChange}
                  />
                  {
                    showError && !props.address.invoiceTitle && <span className="error">Firma Ünvanı boş bırakılamaz</span>
                  }
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
                  Vergi Dairesi*
                </label>
                <div className="field-input">
                  <input
                    className="form-control"
                    type="text"
                    name="taxOffice"
                    onChange={handleChange}
                  />
                  {
                    showError && !props.address.taxOffice && <span className="error">Vergi Dairesi boş bırakılamaz</span>
                  }
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
                  Vergi No*
                </label>
                <div className="field-input">
                  <input
                    className="form-control"
                    type="number"
                    name="taxIdentificationNumber"
                    onChange={handleChange}
                  />
                  {
                    showError && !props.address.taxIdentificationNumber && <span className="error">Vergi No boş bırakılamaz</span>
                  }
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
