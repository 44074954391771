// hooks/useVehicle.ts
import { useState, useEffect } from "react";
import {
  VehicleBrand,
  VehicleModel,
  VehicleYear,
  VehicleFuel,
  VehicleEngine,
  VehicleGear,
  VehicleGearbox,
  VehicleHorsepower,
  VehicleBodyType,
  VehicleSelections,
} from "../interfaces/vehicle";
import {
  fetchBrands,
  fetchModels,
  fetchYears,
  fetchFuels,
  fetchEngines,
  fetchGears,
  fetchGearBox,
  fetchHorsepower,
  fetchBodyType,
  findPartsFromVehicle,
} from "../redux/api/vehicleApi";
import { IVehicle } from "../interfaces/garage";

export const useVehicle = () => {
  const [brands, setBrands] = useState<VehicleBrand[]>([]);
  const [models, setModels] = useState<VehicleModel[]>([]);
  const [years, setYears] = useState<VehicleYear[]>([]);
  const [fuels, setFuels] = useState<VehicleFuel[]>([]);
  const [engines, setEngines] = useState<VehicleEngine[]>([]);
  const [gears, setGears] = useState<VehicleGear[]>([]);
  const [gearBoxes, setGearBoxes] = useState<VehicleGearbox[]>([]);
  const [horsepowers, setHorsepowers] = useState<VehicleHorsepower[]>([]);
  const [bodyTypes, setBodyTypes] = useState<VehicleBodyType[]>([]);
  const [vehicle, setVehicle] = useState<IVehicle | undefined>(
    undefined,
  );

  const [selections, setSelections] = useState<VehicleSelections>({
  });

  useEffect(() => {
    if (brands.length > 0) {
      return;
    }
    fetchBrands().then((response) => {
      const data = response || [];
      setBrands(data);
    });
  }, []);

  useEffect(() => {
    if (selections.brand) {
      fetchModels(selections.brand).then((response) => {
        const data = response || [];
        setModels(data);
        if (data?.length === 1) {
          setSelections({
            brand: selections.brand,
            model: data[0].id,
            year: null,
            fuel: null,
            engine: null,
            gear: null,
            gearbox: null,
            horsepower: null,
            bodyType: null,
          } as VehicleSelections);
        }
      });
    } else {
      setModels([]);
      setSelections({
        brand: selections.brand,
        model: null,
        year: null,
        fuel: null,
        engine: null,
        gear: null,
        gearbox: null,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [selections.brand]);

  useEffect(() => {
    if (selections.brand && selections.model) {
      fetchYears(selections.brand, selections.model).then((response) => {
        const data = response || [];
        setYears(data);
        if (data?.length === 1) {
          setSelections({
            brand: selections.brand,
            model: selections.model,
            year: data[0].id,
            fuel: null,
            engine: null,
            gear: null,
            gearbox: null,
            horsepower: null,
            bodyType: null,
          } as VehicleSelections);
        }
      });
    } else {
      setYears([]);
      setSelections({
        brand: selections.brand,
        model: selections.model,
        year: null,
        fuel: null,
        engine: null,
        gear: null,
        gearbox: null,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [selections.brand, selections.model]);

  useEffect(() => {
    if (selections.brand && selections.model && selections.year) {
      fetchFuels(selections.brand, selections.model, selections.year).then(
        (response) => {
          const data = response || [];
          setFuels(data);
          if (data.length === 1) {
            setSelections({
              brand: selections.brand,
              model: selections.model,
              year: selections.year,
              fuel: data[0].id,
              engine: null,
              gear: null,
              gearbox: null,
              horsepower: null,
              bodyType: null,
            } as VehicleSelections);
          }
        },
      );
    } else {
      setFuels([]);
      setSelections({
        brand: selections.brand,
        model: selections.model,
        year: selections.year,
        fuel: null,
        engine: null,
        gear: null,
        gearbox: null,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [selections.brand, selections.model, selections.year]);

  useEffect(() => {
    if (
      selections.brand &&
      selections.model &&
      selections.year &&
      selections.fuel
    ) {
      fetchEngines(
        selections.brand,
        selections.model,
        selections.year,
        selections.fuel,
      ).then((response) => {
        const data = response || [];
        setEngines(data);
        if (data.length === 1) {
          setSelections({
            brand: selections.brand,
            model: selections.model,
            year: selections.year,
            fuel: selections.fuel,
            engine: data[0].id,
            gear: null,
            gearbox: null,
            horsepower: null,
            bodyType: null,
          } as VehicleSelections);
        }
      });
    } else {
      setEngines([]);
      setSelections({
        brand: selections.brand,
        model: selections.model,
        year: selections.year,
        fuel: selections.fuel,
        engine: null,
        gear: null,
        gearbox: null,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [selections.brand, selections.model, selections.year, selections.fuel]);

  useEffect(() => {
    if (
      selections.brand &&
      selections.model &&
      selections.year &&
      selections.fuel &&
      selections.engine
    ) {
      fetchGears(
        selections.brand,
        selections.model,
        selections.year,
        selections.fuel,
        selections.engine,
      ).then((response) => {
        const data = response || [];
        setGears(data);
        if (data.length === 1) {
          setSelections({
            brand: selections.brand,
            model: selections.model,
            year: selections.year,
            fuel: selections.fuel,
            engine: selections.engine,
            gear: data[0].id,
            gearbox: null,
            horsepower: null,
            bodyType: null,
          } as VehicleSelections);
        }
      });
    } else {
      setGears([]);
      setSelections({
        brand: selections.brand,
        model: selections.model,
        year: selections.year,
        fuel: selections.fuel,
        engine: selections.engine,
        gear: null,
        gearbox: null,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [
    selections.brand,
    selections.model,
    selections.year,
    selections.fuel,
    selections.engine,
  ]);

  useEffect(() => {
    if (
      selections.brand &&
      selections.model &&
      selections.year &&
      selections.fuel &&
      selections.engine &&
      selections.gear
    ) {
      fetchGearBox(
        selections.brand,
        selections.model,
        selections.year,
        selections.fuel,
        selections.engine,
        selections.gear,
      ).then((response) => {
        const data = response || [];
        setGearBoxes(data);
        if (data.length === 1) {
          setSelections({
            brand: selections.brand,
            model: selections.model,
            year: selections.year,
            fuel: selections.fuel,
            engine: selections.engine,
            gear: selections.gear,
            gearbox: data[0].id,
            horsepower: null,
            bodyType: null,
          } as VehicleSelections);
        }
      });
    } else {
      setGearBoxes([]);
      setSelections({
        brand: selections.brand,
        model: selections.model,
        year: selections.year,
        fuel: selections.fuel,
        engine: selections.engine,
        gear: selections.gear,
        gearbox: null,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [
    selections.brand,
    selections.model,
    selections.year,
    selections.fuel,
    selections.engine,
    selections.gear,
  ]);

  useEffect(() => {
    if (
      selections.brand &&
      selections.model &&
      selections.year &&
      selections.fuel &&
      selections.engine &&
      selections.gear &&
      selections.gearbox
    ) {
      fetchHorsepower(
        selections.brand,
        selections.model,
        selections.year,
        selections.fuel,
        selections.engine,
        selections.gear,
        selections.gearbox,
      ).then((response) => {
        const data = response || [];
        setHorsepowers(data);
        if (data.length === 1) {
          setSelections({
            brand: selections.brand,
            model: selections.model,
            year: selections.year,
            fuel: selections.fuel,
            engine: selections.engine,
            gear: selections.gear,
            gearbox: selections.gearbox,
            horsepower: data[0]?.id,
            bodyType: null,
          } as VehicleSelections);
        }
      });
    } else {
      setHorsepowers([]);
      setSelections({
        brand: selections.brand,
        model: selections.model,
        year: selections.year,
        fuel: selections.fuel,
        engine: selections.engine,
        gear: selections.gear,
        gearbox: selections.gearbox,
        horsepower: null,
        bodyType: null,
      } as VehicleSelections);
    }
  }, [
    selections.brand,
    selections.model,
    selections.year,
    selections.fuel,
    selections.engine,
    selections.gear,
    selections.gearbox,
  ]);

  useEffect(() => {
    if (
      selections.brand &&
      selections.model &&
      selections.year &&
      selections.fuel &&
      selections.engine &&
      selections.gear &&
      selections.gearbox &&
      selections.horsepower
    ) {
      fetchBodyType(
        selections.brand,
        selections.model,
        selections.year,
        selections.fuel,
        selections.engine,
        selections.gear,
        selections.gearbox,
        selections.horsepower,
      ).then((response) => {
        const data = response || [];
        setBodyTypes(data);
        if (data.length === 1) {
          setSelections({
            brand: selections.brand,
            model: selections.model,
            year: selections.year,
            fuel: selections.fuel,
            engine: selections.engine,
            gear: selections.gear,
            gearbox: selections.gearbox,
            horsepower: selections.horsepower,
            bodyType: data[0].id,
          });
        }
      });
    } else {
      setBodyTypes([]);
      if (!selections.bodyType) {
        setSelections({
          brand: selections.brand,
          model: selections.model,
          year: selections.year,
          fuel: selections.fuel,
          engine: selections.engine,
          gear: selections.gear,
          gearbox: selections.gearbox,
          horsepower: selections.horsepower,
          bodyType: null,
        });
      }
    }
  }, [
    selections.brand,
    selections.model,
    selections.year,
    selections.fuel,
    selections.engine,
    selections.gear,
    selections.gearbox,
    selections.horsepower,
  ]);

  useEffect(() => {
    if (
      selections.brand &&
      selections.model &&
      selections.year &&
      selections.fuel &&
      selections.engine &&
      selections.gear &&
      selections.gearbox &&
      selections.horsepower &&
      selections.bodyType
    ) {
      findPartsFromVehicle(
        selections.brand,
        selections.model,
        selections.year,
        selections.fuel,
        selections.engine,
        selections.gear,
        selections.gearbox,
        selections.horsepower,
        selections.bodyType,
      ).then((response) => {
        const data = response || {};
        setVehicle(data);
      });
    } else {
      setVehicle(undefined);
      setSelections({ ...selections, bodyType: null });
    }
  }, [
    selections.brand,
    selections.model,
    selections.year,
    selections.fuel,
    selections.engine,
    selections.gear,
    selections.gearbox,
    selections.horsepower,
    selections.bodyType,
  ]);

  return {
    brands,
    models,
    years,
    fuels,
    engines,
    gears,
    gearBoxes,
    horsepowers,
    bodyTypes,
    vehicle,
    selections,
    setSelections,
    setVehicle,
  };
};
