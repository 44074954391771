import React from "react";

interface CheckboxProps {
  id?: string;
  label: any;
  checked: boolean;
  onChange: (checked: boolean) => void;
  required?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  checked,
  onChange,
  required,
}) => {
  return (
    <div className="row" style={{ marginBottom: "5px", marginLeft: "5px" }}>
      <div className="col-xs-12">
        <label className="content-color">
          <input
            id={id}
            type="checkbox"
            className="le-checkbox auto-width inline big"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            required={required}
          />
          <i className="fake-box"></i>
          <span style={{ marginLeft: "5px", fontSize: "11px" }}>{label}</span>
        </label>
      </div>
    </div>
  );
};
