import React from "react";
import "./Order.scss";

interface IOrderPaymentSummaryProps {
  paymentTitle: string;
  paymentTotalTitle: string;
  cartItems: {
    id: number;
    price: number;
    productName: string;
    quantity: number;
    showQuantity?: boolean;
  }[];
  onDelete?: (item: {
    id: number;
    price: number;
    productName: string;
    quantity: number;
  }) => void;
}

export default function OrderPaymentSummary(props: IOrderPaymentSummaryProps) {
  return (
    <div className="order-info p_relative d_block pt_45 pr_50 pb_25 pl_50 mb_50 mt_50">
      <h4 className="sub-title d_block fs_24 lh_30 mb_25">
        {props.paymentTitle}
      </h4>
      <div className="order-product">
        <ul className="order-list clearfix">
          {props.cartItems.filter(item => item.productName).map((item) => (
            <li className="p_relative d_block clearfix pt_17 pb_16">
              {props.onDelete && (
                <button
                  className="fs_15 lh_20 pr-2 remove-btn pull-left"
                  onClick={() => props.onDelete?.(item)}
                >
                  <i className="fa fa-times"></i>
                </button>
              )}

              <h6 className="fs_15 lh_20 pull-left fw_normal">
                {`${item.productName}${item.quantity !== 0 && item.showQuantity !== false ?` x ${item.quantity}`: ''}`}
              </h6>
              <span className="p_relative d_block pull-right fs_15 fw_medium font_family_inter color_black">
                {item.price.toFixed(2)} TL
              </span>
            </li>
          ))}
          <li className="order-total p_relative d_block clearfix pt_17 pb_16">
            <h6 className="fs_16 fw_bold lh_20 pull-left">
              {props.paymentTotalTitle}
            </h6>
            <span className="p_relative d_block pull-right fs_15 fw_bold font_family_inter color_black">
              {props.cartItems
                .reduce((acc, item) => acc + (item.price * item.quantity), 0)
                .toFixed(2)}{" "}
              TL
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
