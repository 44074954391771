import exp from "constants";
import { fetchService, serverUrl } from "./fetchService";

export async function fetchAddressList() {
  const path = "/Addresses/getAddresses";
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function removeAddress(addressId: number) {
  const path = `/Addresses/deleteAddress?addressId=${addressId}`;
  return await fetchService({ url: serverUrl + path, method: "DELETE" });
}

export async function updateAddress(address: any) {
  const path = "/Addresses/editAddress?addressId="+address.id;
  return await fetchService({ url: serverUrl + path, method: "PUT", body: address });
}

export async function fetchCityList() {
  const path = "/Addresses/getCityList";
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function fetchDistrictList(cityId: number) {
  const path = `/Addresses/getDistrictList?cityId=${cityId}`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function fetchNeighborhoodList(districtId: number) {
  const path = `/Addresses/getNeighborhoodList?districtId=${districtId}`;
  return await fetchService({ url: serverUrl + path, method: "GET" });
}

export async function createAddress(address: any) {
  const path = "/Addresses/addAddress";
  return await fetchService({ url: serverUrl + path, method: "POST", body: address });
}
