import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../../redux/slices/userSlice";
import Login from "../login/Login";
import { Link, NavLink, Outlet } from "react-router-dom";
export default function Profile(props: any) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch<any>();

  if (!user?.user) {
    return <Login />;
  }

  return (
    <div className="profile-container">
      <div className="container container--max--xl">
        <div className="row">
          <div className="col-12 col-lg-3 d-flex">
            <div className="account-nav flex-grow-1">
              <h4 className="account-nav__title">Profil Sayfası</h4>
              <ul className="account-nav__list">
                <li className="account-nav__item">
                  <NavLink to="/account/" end>
                    Profil
                  </NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/garage">Garajım</NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/edit-profile">Profili Düzenle</NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/change-password">
                    Şifreyi Değiştir
                  </NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/orders">Sipariş Geçmişi</NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/addresses">Adreslerim</NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/wishlist">Favorilerim</NavLink>
                </li>
                <li className="account-nav__item">
                  <NavLink to="/account/support">Yardım Taleplerim</NavLink>
                </li>
                <li className="account-nav__divider" role="presentation"></li>
                <li className="account-nav__item">
                  <button onClick={() => dispatch(logout())} type="button">
                    Çıkış
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-9 mt-4 mt-lg-0">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
