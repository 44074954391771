import React, { useState } from "react";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAsync,
  registerAsync,
  selectUser,
} from "../../redux/slices/userSlice";
import { IUserLogin, IUserRegister } from "../../interfaces/user";
import { useAppDispatch } from "../../redux/hooks";
import { addItemToCart, clearCart } from "../../redux/api/cartApi";
import { selectCart } from "../../redux/slices/cartSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "../../component/checkbox/Checkbox";
import Modal from "../../component/modal/Modal";
import MembershipAgreement from './MembershipAgreement';
import PersonalDataInformation from "./PersonalDataInformation";
import Information from "./Information";

type FormTypes = "login" | "register" | "forgot";

export default function Login() {
  const { formTypeParam } = useParams();
  const navigate = useNavigate();
  const [formType, setFormType] = useState<FormTypes>(
    (formTypeParam as FormTypes) || "login",
  );
  const [loginForm, setLoginForm] = useState<IUserLogin>({
    email: "",
    password: "",
  });
  const [registerForm, setRegisterForm] = useState<
    IUserRegister & {
      isEmailSubscribed: boolean;
      isSmsSubscribed: boolean;
      isPersonalDataAgreementAccepted: boolean;
      isConsentAgreementAccepted: boolean;
    }
  >({
    email: "",
    newPassword: "",
    phoneNumber: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    isEmailSubscribed: true,
    isSmsSubscribed: true,
    isPersonalDataAgreementAccepted: false,
    isConsentAgreementAccepted: false,
  });

  const [showAydinlatmaMetniModal, setShowAydinlatmaMetniModal] =
    useState(false);
  const [showKisiselVeriKorunumuModal, setShowKisiselVeriKorunumuModal] =
    useState(false);
    const [showMembershipModal, setShowMembershipModal] =
    useState(false);

  const saveAndCloseAydinlatmaMetni = () => {
    // Kaydetme işlemleri yapılabilir
    setShowAydinlatmaMetniModal(false);
  };

  const saveAndCloseKisiselVeriKorunumu = () => {
    // Kaydetme işlemleri yapılabilir
    setShowKisiselVeriKorunumuModal(false);
  };
  const saveAndCloseMembershipModal = () => {
    // Kaydetme işlemleri yapılabilir
    setShowMembershipModal(false);
  };

  const [error, setError] = useState<string | undefined>();

  const dispatch = useAppDispatch();
  const cart = useSelector(selectCart);

  const onSubmit = async (
    e: React.FormEvent<IUserRegister & HTMLFormElement>,
  ) => {
    e.preventDefault();
    const result = await dispatch(registerAsync(registerForm));
    // Kaydettikten sonra yönlendirme veya işlem yapılabilir
  };

  const onLoginSubmit = async () => {
    const result = await dispatch(loginAsync(loginForm));
    await clearCart();
    for await (const item of cart.items) {
      await addItemToCart({
        productId: item.product.id,
        quantity: item.quantity,
      });
    }
    navigate("/profile/dashboard");
  };

  return (
    <div id="logreg-forms">
      {formType === "login" ? (
        <div className="form-signin">
          <h1 className="h3 mb-3 font-weight-normal text-center">Giriş</h1>
          <input
            type="email"
            id="inputEmail"
            value={loginForm.email}
            onChange={(e) =>
              setLoginForm({ ...loginForm, email: e.target.value })
            }
            className="form-control"
            placeholder="Email"
            required
          />
          <input
            type="password"
            id="inputPassword"
            value={loginForm.password}
            onChange={(e) =>
              setLoginForm({ ...loginForm, password: e.target.value })
            }
            className="form-control"
            placeholder="Şifre"
            required
          />
          <button
            onClick={onLoginSubmit}
            className="btn btn-success btn-block"
            type="submit"
          >
            <i className="fas fa-sign-in-alt"></i> Giriş
          </button>
          <div
            className="backbtn"
            onClick={() => setFormType("forgot")}
            id="forgot_pswd"
          >
            Şifremi Unuttum
          </div>
          <hr />
          <button
            className="btn btn-primary btn-block"
            type="button"
            id="btn-signup"
            onClick={() => setFormType("register")}
          >
            <i className="fas fa-user-plus"></i>Yeni Hesap Oluştur
          </button>
        </div>
      ) : formType === "register" ? (
        <form className="form-signin form-register" onSubmit={onSubmit}>
          <h1 className="h3 mb-3 font-weight-normal text-center">Kaydol</h1>
          <input
            type="text"
            id="firstName"
            className="form-control"
            placeholder="Ad"
            required
            onChange={(e) =>
              setRegisterForm({ ...registerForm, firstName: e.target.value })
            }
          />
          <input
            type="text"
            id="lastName"
            className="form-control"
            placeholder="Soyad"
            required
            onChange={(e) =>
              setRegisterForm({ ...registerForm, lastName: e.target.value })
            }
          />
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Email"
            required
            onChange={(e) =>
              setRegisterForm({ ...registerForm, email: e.target.value })
            }
          />
          <input
            type="phone"
            id="phoneNumber"
            className="form-control"
            placeholder="Telefon Numarası"
            required
            onChange={(e) =>
              setRegisterForm({ ...registerForm, phoneNumber: e.target.value })
            }
          />
          <input
            type="password"
            id="newPassword"
            className="form-control"
            placeholder="Şifre"
            required
            onChange={(e) =>
              setRegisterForm({ ...registerForm, newPassword: e.target.value })
            }
          />
          <input
            type="password"
            id="confirmPassword"
            className="form-control"
            placeholder="Şifre Tekrarı"
            required
            onChange={(e) =>
              setRegisterForm({
                ...registerForm,
                confirmPassword: e.target.value,
              })
            }
          />

          <Checkbox
            label="Kampanyalardan haberdar olmak için e-posta almak istiyorum"
            checked={registerForm.isEmailSubscribed}
            onChange={(checked: boolean) =>
              setRegisterForm({ ...registerForm, isEmailSubscribed: checked })
            }
          />
          <Checkbox
            label="Kampanyalardan haberdar olmak için SMS almak istiyorum"
            checked={registerForm.isSmsSubscribed}
            onChange={(checked: boolean) =>
              setRegisterForm({ ...registerForm, isSmsSubscribed: checked })
            }
          />
          <Checkbox
            label={
              <>
                <span
                  id="KisiselVerilerinKorunmasiMetni"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => {setShowKisiselVeriKorunumuModal(true); e.stopPropagation();}}
                >
                  Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metnini
                </span>
                <span> okudum.</span>
              </>
            }
            required
            checked={registerForm.isPersonalDataAgreementAccepted}
            onChange={(checked: boolean) =>
              setRegisterForm({
                ...registerForm,
                isPersonalDataAgreementAccepted: checked,
              })
            }
          />
          <Checkbox
            label={
              <>
                <span
                  id="aydinlatmaRizaMetni"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => {setShowAydinlatmaMetniModal(true);e.stopPropagation();}}
                >
                  Açık Rıza Metnini
                </span>
                <span> ve </span>
                <span
                  id="uyelikSozlesmesi"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => {setShowMembershipModal(true);e.stopPropagation();}}
                >
                  Üyelik Sözleşmesini
                </span>
                <span> okudum, onaylıyorum.</span>
              </>
            }
            required
            checked={registerForm.isConsentAgreementAccepted}
            onChange={(checked: boolean) =>
              setRegisterForm({
                ...registerForm,
                isConsentAgreementAccepted: checked,
              })
            }
          />

          <button className="btn btn-primary btn-block" type="submit">
            <i className="fas fa-user-plus"></i> Kaydol
          </button>
          <div
            className="backbtn"
            onClick={() => setFormType("login")}
            id="cancel_signup"
          >
            <i className="fas fa-angle-left"></i> Geri
          </div>
        </form>
      ) : (
        <form className="form-signin">
          <h1 className="h3 mb-3 font-weight-normal text-center">
            Şifremi Unuttum
          </h1>
          <input
            type="email"
            id="resetEmail"
            className="form-control"
            placeholder="Email"
            required
          />
          <button className="btn btn-primary btn-block" type="submit">
            Şifre Yenile
          </button>
          <div
            className="backbtn"
            onClick={() => setFormType("login")}
            id="cancel_reset"
          >
            <i className="fas fa-angle-left"></i> Geri
          </div>
        </form>
      )}
      {error && <div>{error}</div>}
      {/* Aydınlatma Metni Modal */}
      <Modal
        show={showAydinlatmaMetniModal}
        setShowModal={setShowAydinlatmaMetniModal}
        saveAndClose={saveAndCloseAydinlatmaMetni}
        modalTitle="Aydınlatma Metni"
        showButtons={false} // Alt taraftaki "Kapat" ve "Kaydet ve Kapat" butonlarını gizlemek için
      >
        <Information/>
      </Modal>

      {/* Kişisel Verilerin Korunumu Modal */}
      <Modal
        show={showKisiselVeriKorunumuModal}
        setShowModal={setShowKisiselVeriKorunumuModal}
        saveAndClose={saveAndCloseKisiselVeriKorunumu}
        modalTitle="Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni"
        showButtons={false} // Alt taraftaki "Kapat" ve "Kaydet ve Kapat" butonlarını gizlemek için
      >
        <PersonalDataInformation/>
      </Modal>{/* Üyelik Sözleşmesi Modal */}
      <Modal
        show={showMembershipModal}
        setShowModal={setShowMembershipModal}
        saveAndClose={saveAndCloseMembershipModal}
        modalTitle="Üyelik Sözleşmesi Metni"
        showButtons={false} // Alt taraftaki "Kapat" ve "Kaydet ve Kapat" butonlarını gizlemek için
      >
        <MembershipAgreement/>
      </Modal>
    </div>
  );
}
