import React, { useEffect, useState } from "react";
import { getSupportRequests, createSupportRequest, newSupportMessage, closeSupportRequest, getSupportMessages } from "../../../redux/api/supportsApi";
import Modal from "../../../component/modal/Modal";
import { ESupportRequestReason, SupportMessage, SupportRequest } from "../../../interfaces/support";

const Support = () => {
  const [supportList, setSupportList] = useState<SupportRequest[]>([]);
  const [showNewSupportModal, setShowNewSupportModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [selectedSupport, setSelectedSupport] = useState<SupportRequest | undefined>();
  const [supportMessages, setSupportMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [newSupportData, setNewSupportData] = useState({
    subject: "",
    message: "",
    reason: "",
    orderId: null,
    productId: null
  });

  useEffect(() => {
    getSupports();
  }, []);

  const getSupports = async () => {
    const supports = await getSupportRequests(true, 50, 1);
    setSupportList(supports);
  };

  const handleCreateSupport = async () => {
    const newSupport: Omit<SupportRequest, 'id'> = {
      subject: newSupportData.subject,
      message: newSupportData.message,
      reason: newSupportData.reason as ESupportRequestReason,
      orderId: newSupportData.orderId ?? undefined,
      productId: newSupportData.productId ?? undefined,
      userId: 0,
      status: "OPEN",
      createdAt: undefined,
      updatedAt: undefined
    };
    await createSupportRequest(newSupport as SupportRequest);
    setShowNewSupportModal(false);
    getSupports();
  };

  const handleSendMessage = async () => {    if (selectedSupport && newMessage) {
      await newSupportMessage(selectedSupport.id, newMessage);
      const messages = await getSupportMessages(selectedSupport.id, true, 50, 0);
      setSupportMessages(messages);
      setNewMessage("");
    }
  };

  const handleCloseSupport = async (supportId:number) => {
    await closeSupportRequest(supportId);
    getSupports();
  };

  const openMessagesModal = async (support: SupportRequest) => {
    setSelectedSupport(support);
    const messages = await getSupportMessages(support.id, true, 50, 0);
    setSupportMessages(messages);
    setShowMessagesModal(true);
  };

  return (
    <div className="support-container">
      <button className="btn btn-primary mb-4" onClick={() => setShowNewSupportModal(true)}>
        Yeni Destek Talebi
      </button>

      <div className="support-list">
        {supportList.map((support) => (
          <div key={support.id} className="support-item card mb-3">
            <div className="card-body">
              <h5 className="card-title">{support.subject}</h5>
              <p className="card-text">{support.message}</p>
              <div className="button-group">
                <button className="btn btn-info me-2" onClick={() => openMessagesModal(support)}>
                  Mesajları Görüntüle
                </button>
                <button className="btn btn-success" onClick={() => handleCloseSupport(support.id)}>
                  Çözümlendi
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        show={showNewSupportModal}
        setShowModal={setShowNewSupportModal}
        saveAndClose={handleCreateSupport}
        modalTitle="Yeni Destek Talebi"
      >
        <div className="form-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Konu"
            value={newSupportData.subject}
            onChange={(e) => setNewSupportData({...newSupportData, subject: e.target.value})}
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Mesajınız"
            value={newSupportData.message}
            onChange={(e) => setNewSupportData({...newSupportData, message: e.target.value})}
          />
        </div>
      </Modal>

      <Modal
        show={showMessagesModal}
        setShowModal={setShowMessagesModal}
        saveAndClose={handleSendMessage}
        modalTitle="Destek Mesajları"
      >
        <div className="messages-container">
          {supportMessages.map((message: SupportMessage) => (
            <div key={message.id} className="message-item">
              <p>{message.message}</p>
              <small>{new Date(message.createdAt).toLocaleString()}</small>
            </div>
          ))}
          <div className="new-message-input">
            <textarea
              className="form-control"
              placeholder="Yeni mesaj yazın..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Support;