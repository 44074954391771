// react functional Cart.tsx component

import { useEffect } from "react";
import PageTitle from "../../component/pageTitle/PageTitle";
import CartBody from "../../component/cartBody/CartBody";
import { useSelector } from "react-redux";
import { fetchCartsAsync } from "../../redux/slices/cartSlice";
import { useAppDispatch } from "../../redux/hooks";
import { selectUser } from "../../redux/slices/userSlice";

export default function Cart() {
  const user = useSelector(selectUser);
  const isUserAuth = !!user?.user;
  const dispatch = useAppDispatch();
  // useMemo ile cart'ın içindeki ürünleri alıp CartTable'a gönderiyoruz

  useEffect(() => {
    if (isUserAuth) {
      dispatch(fetchCartsAsync());
    }
  }, []);

  return (
    <div>
      <PageTitle title="Sepet" />
      <CartBody />
    </div>
  );
}
