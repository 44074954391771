import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchBrands, fetchModels } from "../../redux/api/vehicleApi";
import "./BrandBlock.scss";

const BrandBlock = () => {
  const [brandList, setBrandList] = React.useState<any[]>([]);

  const [modelList, setModelList] = React.useState<any[]>([]);

  const initModels = async () => {
    const brandListRes = await fetchBrands();
    setBrandList(brandListRes);
    const resultModels = [];
    for await (const element of brandListRes) {
      const res = await fetchModels(element.id);

      if (!res) {
        return;
      }
      const modelsString = res.map((model: any) => model.name).join(", ");
      resultModels.push({ id: element.id, str: modelsString });
    }
    setModelList(resultModels);
  };

  useEffect(() => {
    initModels();
  }, []);

  return (
    <section className="brand">
      <div className="auto-container">
        <div className="row">
          <div className="common-section-title">
            <div className="section-title">
              <img
                src="/assets/images/icons/featured-nav-left.png"
                alt="icon"
              />
              <h4>Yedek Parçasını Sattığımız Markalar</h4>
            </div>
          </div>
          {brandList.map((brand, index) => (
            <div
              key={brand.name}
              className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3"
            >
              <div className="brand-container p-3">
                <div className="brand-logo">
                  <Link to="/vehicle-product">
                    <img
                      src={`/assets/images/car-icons/${brand.name.toLocaleLowerCase().replace(" ", "-")}.png`}
                      alt="logo"
                    />{" "}
                    <span>{brand.name}</span>
                    <div className="brand-explanation">
                      <p>
                        {`${brand.name} Marka arabanızın ${modelList.find((b) => b.id === brand.id)?.str ?? ""} periyodik değişen parçalarını, motor ve süspansiyon parçaları sitemizden hızlı sipariş verebilirsiniz.`}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandBlock;
