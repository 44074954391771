import React from "react";
import MainBlogDetailsComponent from "../../component/blog/MainBlogDetailsComponent";

const HomeBlogPage = () => {
  return (
    <div className="auto-container">
      <MainBlogDetailsComponent
        contentOne={{
          category: "Oto Yedek Parça",
          title: "Güvenilir E-Ticaret Platformu ile Oto Yedek Parça Alışverişi",
          image: "assets/images/product/blog-1-w.webp",
          text1:
            "Günümüz ticaretinde güvenilir e-ticaret platformları, kişisel ihtiyaçlarımızı karşılarken zaman ve maliyet avantajları sunan vazgeçilmez bir araç haline gelmiştir. Teknolojinin gelişmesiyle birlikte otomobillerimiz için ihtiyaç duyduğumuz oto yedek parça ve bu parçalara dair bilgilere sağlıklı bir şekilde ulaşabilmeniz için tamamen kullanıcı odaklı bir sistem geliştirdik.",
          text2:
            "Otomotiv yedek parça sektöründeki otuz yılı aşkın tecrübemizi, Son Parça sistemi ile sizlere sunmaktan memnuniyet duyuyoruz. Aracınız için doğru yağı mı seçmelisiniz, periyodik bakım ne zaman yapılmalı, frenleme sırasında çıkan ses ne anlama gelir, aracınızda triger kayışı mı zincir mi bulunuyor gibi sorulara yanıt bulabileceğiniz bir platform oluşturduk. Ayrıca, orijinal yedek parça ile yan sanayi parça arasındaki farklar konusunda da bilgi sahibi olabilirsiniz. Yirmi üç araç markasına ait 450.000'den fazla yedek parçayı sizlerle buluşturuyoruz.",
        }}
        contentTwo={{
          heading: "Son Parça",
          paragraph1:
            "Yedek parça hizmetimizi sunarken, araç şase numarası ile parça sorgulama veya kategoriler aracılığıyla doğru parçayı bulmanızı sağlıyoruz. Parçanın kullanım yeri, üretici firma, marka ve fiyat bilgilerine sistemimiz üzerinden kolayca ulaşabilirsiniz. Aklınıza takılan herhangi bir soruda uzman çağrı merkezimizle iletişime geçebilir veya YouTube kanalımız üzerinden görsel bilgilere ulaşabilirsiniz.",
          images: [
            "assets/images/product/blog-2.webp",
            "assets/images/product/blog-3.webp",
          ],
          quote: {
            text: "Doğru ve güvenilir yedek parça ile aracınızın ömrünü uzatabilirsiniz.",
            author: "Son Parça",
          },
          paragraph2:
            "Geliştirdiğimiz sistemle, aracınız için ihtiyaç duyduğunuz yedek parçayı araç şase numarasıyla sorgulayabilir veya araç bilgilerini kullanarak kategorize edebilirsiniz. Uyumlu OEM (Orijinal Parça Kodu) numaraları ürün detaylarında yer almaktadır. Şase numarası ile yedek parça sorgulamak için tıklayınız.",
        }}
        contentThree={{
          title: "Son Parça Garaj Uygulaması",
          text: "Son Parça ailesi olarak geliştirdiğimiz garaj uygulamasıyla, site içerisinde kişiselleştirme yapabilir ve birden fazla aracın yedek parça kayıtlarını güvenle saklayabilirsiniz.",
          listItems: [
            "Son Parça Bakım Robotu",
            "Uygun Fiyatlı Araba Parçaları",
            "Birlikte Büyüyoruz",
          ],
        }}
        author={{
          name: "Henry Ford",
          image: "assets/images/icons/ford.webp",
          description:
            "Bir arabanın her parçası, mühendislik ve güvenilirliğin bir yansımasıdır. Doğru yedek parça, aracınızın ömrünü uzatır.",
        }}
        tags={["Yaratıcı", "Pazarlama", "Fikir"]}
        socialLinks={[
          {
            platform: "Facebook",
            link: "blog-details.html",
            iconClass: "fab fa-facebook-f",
          },
          {
            platform: "Twitter",
            link: "blog-details.html",
            iconClass: "fab fa-twitter",
          },
          {
            platform: "Google+",
            link: "blog-details.html",
            iconClass: "fab fa-google-plus-g",
          },
        ]}
      />
    </div>
  );
};

export default HomeBlogPage;
