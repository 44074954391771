import React from 'react';

interface ContentThreeProps {
    title: string;
    text: string;
    listItems: string[];
}

const ContentThree: React.FC<ContentThreeProps> = ({ title, text, listItems }) => {
    return (
        <div className="content-three">
            <div className="text">
                <h3 className="d_block fs_30 lh_40 fw_sbold mb_25">{title}</h3>
                <p className="font_family_poppins mb_25">{text}</p>
                <ul className="list clearfix p_relative d_block">
                    {listItems.map((item, index) => (
                        <li className="p_relative d_block fs_16 lh_25 fw_medium font_family_inter pl_25 mb_12 color_black" key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ContentThree;
