import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../configureStore";
import {
  addItemToCart,
  clearCart,
  fetchCartItems,
  removeItemFromCart,
  updateCartItemQuantity,
} from "../api/cartApi";
import { ICart, ICartItem } from "../../interfaces/cart";

export interface CartState {
  cart: ICart;
  status: "idle" | "loading" | "failed";
}

export const fetchCartsAsync = createAsyncThunk(
  "cart/fetchCarts",
  async (_, thunkApi) => {
    try {
      const response = await fetchCartItems();
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addCartAsync = createAsyncThunk(
  "cart/addCart",
  async (cart: ICartItem, thunkApi) => {
    try {
      const response = await addItemToCart({
        productId: cart.product.id,
        quantity: cart.quantity,
      });
      return thunkApi.fulfillWithValue(cart);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const removeCartAsync = createAsyncThunk(
  "garage/removeCart",
  async (cartId: number, thunkApi) => {
    try {
      await removeItemFromCart(cartId);
      return thunkApi.fulfillWithValue(cartId);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

//clear cart
export const clearCartAsync = createAsyncThunk(
  "cart/clearCart",
  async (_, thunkApi) => {
    try {
      await clearCart();
      return thunkApi.fulfillWithValue(undefined);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateCartAsync = createAsyncThunk(
  "cart/updateCart",
  async (cart: ICartItem, thunkApi) => {
    try {
      await updateCartItemQuantity({
        productId: cart.product.id,
        quantity: cart.quantity,
      });
      return thunkApi.fulfillWithValue(cart);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialState: CartState = {
  status: "idle",
  //get cart data if it exists
  cart: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart")!)
    : {
        id: 0,
        token: "",
        number: "",
        createdAt: "",
        payment: "",
        status: "",
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0,
        shippingAddress: {
          firstName: "",
          lastName: "",
          company: "",
          country: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          postcode: "",
          email: "",
          phone: "",
        },
        billingAddress: {
          firstName: "",
          lastName: "",
          company: "",
          country: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          postcode: "",
          email: "",
          phone: "",
        },
      },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemInCartNonAuth: (state, action: PayloadAction<ICartItem>) => {
      // İlgili cartItem'ını bul ve ekle
      const existingItem = state.cart.items.find(
        (existingItem: any) =>
          existingItem.product.id === action.payload.product.id,
      );
      //eğer yoksa pushla varsa sayısını bir arttır
      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
        existingItem.total += action.payload.total;
      } else {
        state.cart.items.push(action.payload);
      }
      // Toplam miktarı ve toplam fiyatı güncelle
      state.cart.quantity += action.payload.quantity;
      state.cart.subtotal += action.payload.total;
      // Diğer toplam miktarları güncelleme vb. işlemler de yapılabilir
      // Cart'ı local storage'a kaydet
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    updateItemInCartNonAuth: (state, action: PayloadAction<ICartItem>) => {
      const existingItem = state.cart?.items.find(
        (existingItem: any) =>
          existingItem.product.id === action.payload.product.id,
      );

      // Eğer varsa, miktarı ve toplamı güncelle
      if (existingItem) {
        if (action.payload.quantity === 0) {
          // Eğer miktar 0 ise, ürünü kaldır
          const index = state.cart.items.findIndex(
            (item: any) => item.product.id === action.payload.product.id,
          );
          if (index !== -1) {
            state.cart.items.splice(index, 1);
          }
        } else {
          existingItem.quantity = action.payload.quantity;
          existingItem.total = action.payload.total;
        }
      } else {
        // Eğer yoksa, yeni bir öğe olarak ekle
        state.cart?.items.push(action.payload);
      }
      const total = state.cart.items.reduce(
        (acc: number, item: any) => acc + item.product.price * item.quantity,
        0,
      );

      // Toplam miktarı ve toplam fiyatı güncelle
      state.cart!.quantity += action.payload.quantity;
      state.cart!.subtotal = total;
      state.cart!.total = total;
      // Diğer toplam miktarları güncelleme vb. işlemler de yapılabilir
      // Cart'ı local storage'a kaydet
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCartsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const total = action.payload?.reduce(
            (acc: number, item: any) =>
              acc + item.product.price * item.quantity,
            0,
          );
          state.cart.items = action.payload?.map((item: any) => {
            return {
              product: item.product,
              quantity: item.quantity,
              price: item.product.price,
              total: item.product.price * item.quantity,
            } as ICartItem;
          });
          state.cart.total = total;
          state.cart.subtotal = total;
        }
        // Cart'ı local storage'a kaydet
        localStorage.setItem("cart", JSON.stringify(state.cart));
        state.status = "idle";
      })
      .addCase(fetchCartsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addCartAsync.fulfilled, (state, action) => {
        const existingItem = state.cart?.items.find(
          (existingItem: any) =>
            existingItem.product.id === action.payload.product.id,
        );

        // Eğer varsa, miktarı ve toplamı güncelle
        if (existingItem) {
          existingItem.quantity += action.payload.quantity;
          existingItem.total += action.payload.total;
        } else {
          // Eğer yoksa, yeni bir öğe olarak ekle
          state.cart?.items.push(action.payload);
        }

        // Toplam miktarı ve toplam fiyatı güncelle
        state.cart!.quantity += action.payload.quantity;
        state.cart!.subtotal += action.payload.total;
        // Diğer toplam miktarları güncelleme vb. işlemler de yapılabilir
        // Cart'ı local storage'a kaydet
        localStorage.setItem("cart", JSON.stringify(state.cart));
      })
      .addCase(removeCartAsync.fulfilled, (state, action) => {
        // ID'si verilen ürünü bul
        const index =
          state.cart?.items.findIndex(
            (item: any) => item.product.id === action.payload,
          ) || -1;

        // Eğer bulunduysa, ürünü kaldır ve toplamları güncelle
        if (index !== -1 && state.cart) {
          const deletedItem = state.cart!.items[index];
          state.cart.quantity -= deletedItem.quantity;
          state.cart.subtotal -= deletedItem.total;
          state.cart.items.splice(index, 1);
          // Diğer toplam miktarları güncelleme vb. işlemler de yapılabilir
        }
        // Cart'ı local storage'a kaydet
        localStorage.setItem("cart", JSON.stringify(state.cart));
      })
      .addCase(updateCartAsync.fulfilled, (state, action) => {
        // ID'si verilen ürünü bul ve güncelle
        const index =
          state.cart?.items.findIndex(
            (item: any) => item.product.id === action.payload.product.id,
          ) || -1;
        // Eğer bulunduysa, ürünü güncelle ve toplamları güncelle
        if (index !== -1 && state.cart) {
          state.cart!.items[index] = action.payload;
          state.cart!.quantity += action.payload.quantity;
          state.cart!.subtotal += action.payload.total;
          // Diğer toplam miktarları güncelleme vb. işlemler de yapılabilir
          // Cart'ı local storage'a kaydet
          localStorage.setItem("cart", JSON.stringify(state.cart));
        }
      });
  },
});
export const { addItemInCartNonAuth, updateItemInCartNonAuth } =
  cartSlice.actions;
export const selectCart = (state: RootState) => state.cart.cart;
export default cartSlice.reducer;
