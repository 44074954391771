import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../component/pageTitle/PageTitle";
import OrderBody from "../../component/orderBody/OrderBody";
import { useSelector } from "react-redux";
import { selectCart } from "../../redux/slices/cartSlice";
import { fetchShippingPrices } from "../../redux/api/orderApi";
import { fetchAddressListAsync } from "../../redux/slices/addressSlice";
import { useAppDispatch } from "../../redux/hooks";
import { toast } from "react-toastify";
import { IOrderDetail } from "../../interfaces/order";
import OrderSuccessSummary from "../../component/orderBody/OrderSuccessSummary";
import { clearCart } from "../../redux/api/cartApi";

export default function Order() {
  const cart = useSelector(selectCart);
  const dispatch = useAppDispatch();

  const [shippingCompanyList, setShippingCompanyList] = useState([]);
  useEffect(() => {
    fetchShippingPrices().then((res) => {
      setShippingCompanyList(res);
    });
    dispatch(fetchAddressListAsync());
  }, []);
  const [successOrder, setSuccessOrder] = useState<IOrderDetail | null>(null);

  const cartItems = cart.items.map((c) => ({
    id: c.product.id,
    price: c.price,
    productName: c.product.name,
    quantity: c.quantity,
  }));
  return (
    <div>
      <PageTitle
        title="Sipariş Onay"
        breadcrumbs={[
          { title: "Anasayfa", path: "/" },
          { title: "Sepet", path: "/cart" },
        ]}
      />
      {
        cart.items.length === 0 && (
          <div className="alert alert-warning">
            Sepetinizde ürün bulunmamaktadır.
          </div>
        )
      }
      {successOrder && <OrderSuccessSummary order={successOrder} />}
      {!successOrder && cart.items.length !== 0 && (
        <OrderBody
          cartItems={cartItems}
          shippingCompanyList={shippingCompanyList}
          setSuccessfulOrder={(order) => {
            toast("Siparişiniz alındı. Teşekkürler.");
            setSuccessOrder(order);
            clearCart();
            window.scrollTo({ top: 300, behavior: "smooth" });
          }}
        />
      )}
    </div>
  );
}
