import React from "react";
import MainBlogDetailsComponent from "../../component/blog/MainBlogDetailsComponent";

const ChassisNoBlog: React.FC = () => {
  return (
    <div className="auto-container">
      <MainBlogDetailsComponent
        contentOne={{
          category: "Oto Yedek Parça",
          title: "Şasi Numarası ile Yedek Parça Sorgulama",
          image: "assets/images/product/chassis-1.webp",
          text1:
            "Şasi numarası ile yedek parça sorgulama, araçların %100 uyumlu yedek parçalarını listelendiği güvenilir Son Parca sistemidir. Sorgulama sonucunda gösterilen yedek parçalar, girilen şasi numarası ile tamamen uyumludur. Bu sistem sayesinde, parçaların orijinal, yan sanayi veya muadil fiyatlarını görebilir ve OEM (Orijinal Parça Numarası) bilgilerine erişebilirsiniz.",
          text2:
            'Şasi numarası ile yedek parça bulma işlemini, seçtiğiniz aracı www.sonparca.com adresinde görebilirsiniz. Dilerseniz başka bir şasi numarasıyla sorgulama yapabilir ya da "Araçtan Parça Bul" seçeneğiyle marka, model, üretim yılı, motor, vites, yakıt türü ve kasa gibi araç özelliklerini seçerek yedek parçalara ulaşabilirsiniz. Farklı markaların parçaları arasında fiyatlar değişiklik gösterebilir.',
        }}
        contentTwo={{
          heading: "Şasi Numarası ile Yedek Parça Nasıl Bulunur?",
          paragraph1:
            "Sitemizde 'Şasiden Parça Bul' seçeneğine tıklayarak başlayabilirsiniz. Aracınızın şasi numarasını girdikten sonra 'Araç Bul' butonuna basarak sistem, aracınızın teknik bilgilerine ulaşır ve uyumlu yedek parçaları belirli kategorilerde size sunar. Eğer şasi numarası ile uygun parçayı bulamazsanız, 'Araçtan Parça Bul' seçeneği ile aracınızın marka, model, üretim yılı, motor ve vites bilgilerini girerek yedek parçalara ulaşabilirsiniz.",
          images: [
            "assets/images/product/chassis-3.webp",
            "assets/images/product/chassis-4.webp",
          ],
          quote: {
            text: "Şasi numarasıyla veya araç bilgileriyle parçaları bulamazsanız ya da tereddüt ederseniz, 0850 840 34 00 numaralı müşteri hizmetlerimizden destek alabilirsiniz. Doğru parça bulma süreci için şasi numarasını yanınızda bulundurmanız önemlidir.",
            author: "Yedek Parça Uzmanı",
          },
          paragraph2:
            "Ayrıca, WhatsApp hattımıza (0850 840 34 00) istediğiniz parçayı ve şasi numaranızı göndererek uzmanlarımızdan hızlıca destek alabilirsiniz. Yedek parça uzmanlarımız sizinle iletişime geçip en doğru bilgileri sağlayacaktır.",
        }}
        contentThree={{
          title: "Şasi Numarası Nedir? Ne İşe Yarar?",
          text: "Şasi numarası, araç kimlik numarasıdır ve araç üretici firması, üretim yılı, motor hacmi, renk, kasa tipi gibi önemli bilgileri içerir. Bu numara, aracın servis ve bakım kayıtları, trafik kazaları ve sigorta bilgileri gibi bilgilerin kaydedildiği bir koddur.",
          listItems: [
            "Araç Kimlik Numarası (Vehicle Identification Number - VIN), aracın üretici firması, üretildiği yıl, motor hacmi, kasa bilgileri ve kullanılan yedek parçalar gibi detayları içerir.",
            "Son Parca.com.tr olarak 30 yılı aşkın yedek parça tecrübemizi, geliştirdiğimiz sistem ile şasi numarası üzerinden sunarak, araç sahiplerine güvenilir ve doğru yedek parçaları ulaştırmayı hedefliyoruz.",
            "Şasi numarası, otomotiv sektöründe araç kimliğinin karşılığıdır. Bu numara, aracınızın özellikleri ve geçmişi hakkında bilgi verir, tıpkı bir insanın T.C. kimlik numarası gibi araçlar için benzersiz bir kimlik görevi görür.",
          ],
        }}
      />
    </div>
  );
};

export default ChassisNoBlog;
