import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IMyVehicle, IVehicle } from "../../../interfaces/garage";
import {
  selectVehicles,
  selectGarageStatus,
  fetchVehiclesAsync,
  addVehicleAsync,
  removeVehicleAsync,
  setSelectedMyVehicle,
} from "../../../redux/slices/garageSlice";
import { useAppDispatch } from "../../../redux/hooks";
import "./Garage.scss";
import VehicleSelector from "../../../component/selectVehicle/SelectVehicle";
import { useNavigate } from "react-router-dom";

const Garage: React.FC = () => {
  const dispatch = useAppDispatch();
  const vehicles = useSelector(selectVehicles);
  const status = useSelector(selectGarageStatus);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchVehiclesAsync());
  }, []);

  const handleAddVehicle = async (
    vehicle: IVehicle,
    year: number,
    name: string,
  ) => {
    vehicle.year = year;
    vehicle.vehicleName = name;
    dispatch(addVehicleAsync(vehicle));
  };

  const handleRemoveVehicle = async (vehicleId: number) => {
    dispatch(removeVehicleAsync(vehicleId));
  };

  const handleMaintainVehicle = async (vehicle: IMyVehicle) => {
    dispatch(setSelectedMyVehicle(vehicle));
    //route to maintenance page
    navigate("/maintain-robot");
  };

  const handleVehicleProduct = async (vehicle: IMyVehicle) => {
    dispatch(setSelectedMyVehicle(vehicle));
    //route to vehicle product page
    navigate("/vehicle-product");
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>Garaj</h5>
      </div>

      {status === "loading" ? (
        <p>Yükleniyor...</p>
      ) : (
        <React.Fragment>
          {vehicles && vehicles.length > 0 && (
            <div className="card-body card-body--padding--2">
              <div className="vehicles-list vehicles-list--layout--account">
                <div className="vehicles-list__body">
                  {vehicles.map((myVehicle: IMyVehicle) => {
                    const vehicle = myVehicle.vehicle;
                    return (
                      <div
                        key={myVehicle.id}
                        className="vehicles-list__item card"
                      >
                        <div className="vehicles-list_item-image mr-1">
                          <img
                            src={
                              vehicle?.images?.[0] ||
                              "/assets/images/garage-car.webp"
                            }
                            alt={vehicle.model.name}
                          />
                        </div>
                        <div className="vehicles-list__item-info">
                          <div className="vehicles-list__item-title">
                            {myVehicle.vehicleName}
                          </div>
                          <div className="vehicles-list__item-name">
                            {`${vehicle.model.name} ${vehicle.model.brand?.name || ""} (${myVehicle.year}) ${vehicle.bodyType}`}
                          </div>
                          <div className="vehicles-list__item-details">
                            {"Motor: " +
                              vehicle.engine +
                              " " +
                              vehicle.fuelType +
                              " " +
                              vehicle.horsepowerKw}
                          </div>
                          <div className="vehicles-list__item-links">
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                myVehicle?.id &&
                                handleRemoveVehicle(myVehicle.id)
                              }
                            >
                              Sil
                            </button>
                            <button
                              className="ml-2 btn btn-secondary btn-sm"
                              onClick={() => handleMaintainVehicle(myVehicle)}
                            >
                              Bakım
                            </button>
                            <button
                              className="ml-2 btn btn-primary btn-sm"
                              onClick={() => handleVehicleProduct(myVehicle)}
                            >
                              Tüm Ürünleri Listele
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className="card-footer">
            <VehicleSelector onSubmit={handleAddVehicle} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Garage;
