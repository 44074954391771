import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../configureStore";
import { IGetOrderList, IGivenOrder, IOrder, IOrderDetail } from "../../interfaces/order";
import { fetchOrderItems } from "../api/orderApi";

export interface OrderState {
  order: IOrder;
  orderList: IOrderDetail[];
  status: "idle" | "loading" | "failed";
}

export const fetchOrdersAsync = createAsyncThunk(
  "order/fetchOrders",
  async (orderListParam:IGetOrderList, thunkApi) => {
    try {
      const response:any = await fetchOrderItems(orderListParam);
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialState: OrderState = {
  order: {
    paymentType: "HAVALE/EFT",
    orderList: [],
  },
  orderList: [],
  status: "idle",
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrder: (state: OrderState, action: PayloadAction<Array<IGivenOrder>>) => {
      state.order.orderList = action.payload;
    },
    setPaymentType: (state: OrderState, action: PayloadAction<string>) => {
      state.order.paymentType = action.payload as 'Iyzico' | 'HAVALE/EFT' | 'Kapıda Ödeme';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrdersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.orderList = action.payload;
      })
      .addCase(fetchOrdersAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const { addOrder, setPaymentType } = orderSlice.actions;
export const selectOrderList = (state: RootState) => state.order.orderList;
export const selectPaymentType = (state: RootState) => state.order.order.paymentType;
export default orderSlice.reducer;
