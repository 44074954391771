import React from 'react'

interface RateProps {
  onRateChange?: (rate: number) => void;
  rate: number;
}

export default function Rate(props: RateProps) {
  return (
    <div>
     <div className="rating">
        {Array.from({ length: 5 }, (_, index) => (
          <i
            key={index}
            className={`fa fa-star ${props.rate > index ? "active" : ""}`}
            onClick={() => props.onRateChange && props.onRateChange(index + 1)}
          ></i>
        ))}
      </div> 
    </div>
  )
}
