import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAddress, IAddressData } from "../../interfaces/address";
import {
  createAddress,
  fetchAddressList,
  removeAddress,
  updateAddress,
} from "../api/addressApi";
import { RootState } from "../configureStore";

export interface AddressState {
  addressList: IAddress[];
  status: "idle" | "loading" | "failed";
}

const initialState: AddressState = {
  addressList: [],
  status: "idle",
};

export const fetchAddressListAsync = createAsyncThunk(
  "address/fetchAddressList",
  async (_, thunkApi) => {
    try {
      const response = await fetchAddressList();
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addAddressAsync = createAsyncThunk(
  "address/addAddress",
  async (address: IAddressData, thunkApi) => {
    try {
      // add address to server
      const response = await createAddress(address);
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const removeAddressAsync = createAsyncThunk(
  "address/removeAddress",
  async (addressId: number, thunkApi) => {
    try {
      await removeAddress(addressId);
      // remove address from server
      return thunkApi.fulfillWithValue(addressId);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateAddressAsync = createAsyncThunk(
  "address/updateAddress",
  async (address: IAddress, thunkApi) => {
    try {
      // update address on server
      const response = await updateAddress(address);
      if (response?.errors) {
        return thunkApi.rejectWithValue(response.errors);
      }
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAddressListAsync.fulfilled, (state, action) => {
        state.addressList = action.payload;
        state.status = "idle";
      })
      .addCase(fetchAddressListAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addAddressAsync.fulfilled, (state, action) => {
        state.addressList.push(action.payload);
      })
      .addCase(removeAddressAsync.fulfilled, (state, action) => {
        state.addressList = state.addressList.filter(
          (address) => address.id !== action.payload,
        );
      })
      .addCase(updateAddressAsync.fulfilled, (state, action) => {
        if (action?.payload?.id === undefined) {
          return;
        }
        state.addressList = state.addressList.map((address) =>
          address.id === action.payload.id ? action.payload : address,
        );
      })
      .addCase(updateAddressAsync.rejected, (state, action) => {
        console.log(action.error.message);
      })
      .addCase(addAddressAsync.rejected, (state, action) => {
        console.log(action.error.message);
      })
      .addCase(removeAddressAsync.rejected, (state, action) => {
        console.log(action.error.message);
      });
  },
});

export const selectAddressList = (state: RootState) =>
  state.address.addressList;

export const selectDefaultAddress = (state: RootState) =>
  state.address.addressList.find((address) => address.default) ||
  ({} as IAddressData);
export const selectAddressStatus = (state: RootState) => state.address.status;
export const addressReducer = addressSlice.reducer;

export default addressSlice.reducer;
