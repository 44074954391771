import React from 'react'
import Banner from '../../component/banner/Banner'
import BrandBlock from '../../component/brandBlock/BrandBlock'
import HomeBlogPage from './HomeBlogPage'
import HomeCategoryBlock from './HomeCategoryBlock'

export default function Home() {
  return (
    <>
      <Banner/>
      <HomeCategoryBlock/>
      <BrandBlock />
      <HomeBlogPage />
    </>
  )
}
