import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { changePasswordAsync, selectUser } from "../../../redux/slices/userSlice";
import { useSelector } from "react-redux";

interface ChangePasswordState {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const email = useSelector(selectUser)?.user?.email || "";
  const [changePasswordState, setChangePasswordState] =
    React.useState<ChangePasswordState>({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangePasswordState({
      ...changePasswordState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (
    e: React.FormEvent<ChangePasswordState & HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (changePasswordState.newPassword !== changePasswordState.confirmNewPassword) {
      // alert("Şifreler uyuşmuyor");
      return;
    }
    // form submit işlemleri
    dispatch(changePasswordAsync({
      email,
      oldPassword: changePasswordState.currentPassword,
      newPassword: changePasswordState.newPassword,
    }));
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h5>Şifre Değiştir</h5>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="currentPassword">Mevcut Şifreniz</label>
            <input
              type="password"
              className="form-control"
              id="currentPassword"
              placeholder="Mevcut Şifreniz"
              value={changePasswordState.currentPassword}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">Yeni Şifreniz</label>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              placeholder="Yeni Şifreniz"
              value={changePasswordState.newPassword}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmNewPassword">Yeni Şifrenizi Onaylayın</label>
            <input
              type="password"
              className="form-control"
              id="confirmNewPassword"
              placeholder="Yeni Şifrenizi Onaylayın"
              value={changePasswordState.confirmNewPassword}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Kaydet
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
