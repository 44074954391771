import {
  IUser,
  IUserEditProfile,
  IUserLogin,
  IUserRegister,
  IUserChangePassword,
} from "../../interfaces/user";
import { fetchService, serverUrl } from "./fetchService";

// A mock function to mimic making an async request for data
export async function login(user: IUserLogin) {
  const path = "/Account/signIn";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    body: user,
  });
}

export async function register(user: IUserRegister) {
  const path = "/Account/signUp";
  return await fetchService({
    url: serverUrl + path,
    method: "PUT",
    body: user,
  });
}

export async function resetPassword(user: IUserChangePassword) {
  const path = "/Account/resetPassword";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    body: user,
  });
}

export async function editProfile(user: IUserEditProfile) {
  const path = "/Account/editProfile";
  return await fetchService({
    url: serverUrl + path,
    method: "PUT",
    body: user,
  });
}

export async function changePassword(user: IUserChangePassword) {
  const path = "/Account/changePassword";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    body: user,
  });
}
