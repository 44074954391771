import React from 'react';
import './MembershipAgreement.scss';

const Information = () => {
  return (
    <p>
          <strong>Aydınlatma Metni</strong>
          <br />
          <div>
            <h2>Kişisel Verilerin İşlenmesine Dair Açık Rıza Beyanı</h2>
            <p>
              Şirketimiz Sonparca.com ("Şirket"),{" "}
              <a href="https://www.sonparca.com">www.sonparca.com</a> üzerinde
              üye olmak suretiyle açıklamış olduğum kişisel verilerimin (kimlik,
              iletişim, araç bilgileri, işlem güvenliği, pazarlama verileri);
              üyelik kaydımın ve hesabımın oluşturulması, buna ilişkin
              kayıtların tutulması, Şirket tarafından sunulan ürün ve
              hizmetlerden faydalanılmasını sağlamak ve yürütülen ticari
              faaliyetlerin gerçekleştirilmesi için gerekli çalışmaların iş
              birimleri tarafından yapılması ve ilgili iş süreçlerinin
              yürütülmesi, Şirket’in ticari ve/veya iş stratejilerinin
              planlanması ve icrası, 6563 Sayılı Elektronik Ticaretin
              Düzenlenmesi Hakkında Kanun ve ilgili Yönetmelik hükümleri
              uyarınca müşteri anketi, promosyon, tanıtım, kampanyalar başta
              olmak üzere telefon, çağrı merkezi, otomatik arama makineleri,
              akıllı ses kaydedici sistemler, elektronik posta, kısa mesaj
              hizmeti gibi vasıtalar kullanılarak elektronik ortamda ticari
              amaçlarla veri, ses ve görüntü içerikli iletiler gönderilmesi
              amacıyla işlenmesine; Şirket’in yurtiçi ve yurtdışında bulunan iş
              ortaklarına, tedarikçilerine, barındırma hizmet sağlayıcılarına
              (hosting servisleri), kanunen yetkili kamu kurum ve kuruluşları
              ile özel kurumlara aktarılmasına rıza gösteriyorum.
            </p>
          </div>
        </p>
  );
};

export default Information;