export function divideArrayIntoGroups<T>(array: T[], groupSize: number): T[][] {
  const dividedArray: T[][] = [];
  if (array === undefined || array.length === 0 || groupSize <= 0) {
    return dividedArray;
  }
  for (let i = 0; i < array.length; i += groupSize) {
    dividedArray.push(array.slice(i, i + groupSize));
  }
  return dividedArray;
}
