import React from 'react';
import { Link } from 'react-router-dom';

interface PostShareOptionProps {
    tags: string[];
    socialLinks: {
        platform: string;
        link: string;
        iconClass: string;
    }[];
}

const PostShareOption: React.FC<PostShareOptionProps> = ({ tags, socialLinks }) => {
    return (
        <div className="post-share-option clearfix p_relative d_block pt_35 pb_35 mb_70">
            <ul className="tags-list clearfix pull-left">
                <li className="p_relative pull-left mr_20"><h6 className="fs_16 fw_medium lh_40">Tags:</h6></li>
                {tags.map((tag, index) => (
                    <li className="p_relative pull-left mr_10" key={index}>
                        <Link to="blog-details.html" className="p_relative d_iblock fs_14 lh_20 font_family_poppins color_black b_radius_5">{tag}</Link>
                    </li>
                ))}
            </ul>
            <ul className="social-list clearfix pull-right">
                <li className="p_relative pull-left mr_20"><h6 className="fs_16 fw_medium lh_40">Share on:</h6></li>
                {socialLinks.map((social, index) => (
                    <li className="p_relative pull-left mr_10" key={index}>
                        <Link to={social.link} className="p_relative d_iblock fs_14 lh_20 font_family_poppins b_radius_50 w_40 h_40 lh_40 centred"><i className={social.iconClass}></i></Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PostShareOption;
