import React from 'react'

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  setShowModal: (show: boolean) => void;
  saveAndClose: () => void;
  modalTitle: string;
  children: React.ReactNode;
  sizeClass?: string;
  showButtons?: boolean;
}

export default function Modal(props: ModalProps) {
  const showModal = props.show;
  const setShowModal = props.setShowModal;
  const sizeClass = props.sizeClass;
  const {showButtons = true} = props;
  return (
    <div className={`modal ${props.className ? props.className : ''}`} style={{'display':showModal ? 'block': 'none'}}>
        <div className={`modal-dialog show ${sizeClass ? sizeClass : ''}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.modalTitle}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >X</button>
            </div>
            <div className="modal-body">
              {props.children}
            </div>
            {
              showButtons &&
              <div className="modal-footer pb-5">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setShowModal(false)}
                >
                  Kapat
                </button>
                <button type="submit" className="btn btn-primary" onClick={props.saveAndClose}>
                  Kaydet ve Kapat
                </button>
              </div>

            }
          </div>
        </div>
      </div>
  )
}
