import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import {
  fetchVehicles,
  addVehicle,
  removeVehicle,
} from "../api/garageApi";
import { CarBrand, IMyVehicle, IVehicle } from "../../interfaces/garage"; // Define this interface as necessary


interface GarageState {
  vehicles: IMyVehicle[];
  selectedMyVehicle?: IMyVehicle;
  selectedVehicle?: IVehicle;
  selectedBrand?: CarBrand;
  vin?:string;
  status: "idle" | "loading" | "failed";
}

const initialState: GarageState = {
  vehicles: [],
  status: "idle",
};

export const fetchVehiclesAsync = createAsyncThunk(
  "garage/fetchVehicles",
  async (_, thunkApi) => {
    try {
      const response = await fetchVehicles();
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addVehicleAsync = createAsyncThunk(
  "garage/addVehicle",
  async (vehicle: IVehicle, thunkApi) => {
    try {
      const response =await addVehicle(vehicle);
      return thunkApi.fulfillWithValue(response);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const removeVehicleAsync = createAsyncThunk(
  "garage/removeVehicle",
  async (vehicleId: number, thunkApi) => {
    try {
      await removeVehicle(vehicleId);
      return thunkApi.fulfillWithValue(vehicleId);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const garageSlice = createSlice({
  name: "garage",
  initialState,
  reducers: {
    setSelectedMyVehicle: (state, action: PayloadAction<IMyVehicle>) => {
      state.selectedMyVehicle = action.payload;
    },
    setSelectedVehicle: (state, action: PayloadAction<IVehicle>) => {
      state.selectedVehicle = action.payload;
    },
    setSelectedBrand: (state, action: PayloadAction<CarBrand>) => {
      state.selectedBrand = action.payload;
    },
    setVin: (state, action: PayloadAction<string>) => {
      state.vin = action.payload;
    },
    clearSelectedVehicle: (state) => {
      state.selectedVehicle = undefined
    },
    clearSelectedMyVehicle: (state) => {
      state.selectedMyVehicle = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehiclesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVehiclesAsync.fulfilled, (state, action) => {
        state.vehicles = action.payload;
        state.status = "idle";
      })
      .addCase(fetchVehiclesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addVehicleAsync.fulfilled, (state, action) => {
        state.vehicles.push(action.payload);
      })
      .addCase(removeVehicleAsync.fulfilled, (state, action) => {
        state.vehicles = state.vehicles.filter((v) => v.id !== action.payload);
      });
  },
});

export const selectVehicles = (state: RootState) => state.garage.vehicles;
export const selectGarageStatus = (state: RootState) => state.garage.status;
export const selectSelectedVehicle = (state: RootState) => state.garage.selectedVehicle;
export const selectSelectedMyVehicle = (state: RootState) => state.garage.selectedMyVehicle;
export const selectVin = (state: RootState) => state.garage.vin;
export const { setSelectedMyVehicle, setSelectedVehicle, setSelectedBrand, setVin, clearSelectedMyVehicle, clearSelectedVehicle } = garageSlice.actions;

export default garageSlice.reducer;
