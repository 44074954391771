import { fetchService, serverUrl } from "./fetchService";

export async function getInstallmentOptions(productId:number) {
  const path = `/Payment/getInstallmentOptions?productId=${productId}`;
  return await fetchService({
    url: serverUrl + path,
    method: "GET",
    throwErrors: false,
  });
}

export async function createIyzicoPayment() {
  const path = "/Payment/createIyzicoPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

export async function createIyzicoCreditCardPayment() {
  const path = "/Payment/createIyzicoPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}
export async function createOnDoorPayment() {
  const path = "/Payment/createMoneyTransferPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

export async function createEftHavalePayment() {
  const path = "/Payment/createMoneyTransferPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}
