import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import {
  fetchCategories,
  fetchMainCategory,
  fetchProducts,
} from "../api/productApi";
import {
  ICategory,
  IProduct,
  IProductFetchAll,
} from "../../interfaces/product";
import { clear } from "console";

export interface ProductState {
  products: Array<IProduct>;
  mainCategories: Array<ICategory>;
  categories: Array<ICategory>;
  status: "idle" | "loading" | "failed";
  page: number;
  pageSize: number;
  category?: string;
  selectedCategory?: ICategory;
  searchQuery?: string;
}

const initialState: ProductState = {
  products: [],
  mainCategories: [],
  categories: [],
  page: 1,
  pageSize: 16,
  status: "idle",
};

export const productsAsync = createAsyncThunk(
  "Product/list",
  async (prodcutListParam: IProductFetchAll, thunkApi) => {
    try {
      const response = await fetchProducts(prodcutListParam);
      // The value we return becomes the `fulfilled` action payload
      return thunkApi.fulfillWithValue(response.items);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const mainCategoryAsync = createAsyncThunk(
  "Product/getParentProductCategories",
  async () => {
    const response = await fetchMainCategory();
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const categoriesAsync = createAsyncThunk(
  "Product/getCategories",
  async () => {
    const response = await fetchCategories();
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<ICategory>) => {
      state.selectedCategory = action.payload;
    },
    clearSelectedCategory: (state) => {
      state.selectedCategory = undefined;
    },
    clearSearchQuery: (state) => {
      state.searchQuery = undefined
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeCategory: (state, action: PayloadAction<string>) => {
      state.category += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(productsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(productsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.products = action.payload;
      })
      .addCase(productsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(mainCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(mainCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mainCategories = action.payload.sort(
          (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber,
        );
      })
      .addCase(mainCategoryAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(categoriesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(categoriesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.categories = action.payload.sort(
          (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber,
        );
      })
      .addCase(categoriesAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { incrementPage, decrementPage, changeCategory, setSearchQuery, setSelectedCategory, clearSelectedCategory, clearSearchQuery } =
  productSlice.actions;
export const selectProducts = (state: RootState) => state.product.products;
export const selectMainCategories = (state: RootState) =>
  state.product.mainCategories;
export const selectCategories = (state: RootState) => state.product.categories;
export const selectSelectedCategory = (state: RootState) =>
  state.product.selectedCategory;
export const selectProductStatus = (state: RootState) => state.product.status;
export const selectSearchQuery = (state: RootState) => state.product.searchQuery;

export default productSlice.reducer;
