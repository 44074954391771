import PageTitle from "../../component/pageTitle/PageTitle";
import ProductGrid from "../../component/productGrid/ProductGrid";


export default function VehicleProduct() {
  
  return (
    <div className="mb-3">
      <PageTitle title="Araç Ürünleri" />
      <ProductGrid pagingActive/>
    </div>
  );
}
