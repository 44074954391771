import React, { useEffect } from "react";
import ProductGrid from "../../component/productGrid/ProductGrid";
import { useParams } from "react-router-dom";
import {
  fetchCategoriesById,
  fetchProducts,
} from "../../redux/api/productApi";
import { useSelector } from "react-redux";
import { selectSelectedCategory, setSelectedCategory } from "../../redux/slices/productSlice";
import { IBrand, IProductResponse } from "../../interfaces/product";
import { useAppDispatch } from "../../redux/hooks";

export default function Categories() {
  const { categoryId } = useParams();
  const dispatch = useAppDispatch();
  const categoryIdInt = parseInt(categoryId!.split("-c-")[1]);

  useEffect(() => {
    if (categoryIdInt) {
      fetchCategoriesById(categoryIdInt + "").then((response) => {
        dispatch(setSelectedCategory(response));
      });
    }
  }, [categoryIdInt, categoryId]);

  return (
    <div>
      <ProductGrid
        pagingActive
      />
    </div>
  );
}
