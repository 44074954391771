import React from "react";
import "./MobileScrollTopButton.scss";
export default function MobileScrollTopButton() {
  const [showScroll, setShowScroll] = React.useState(false);

  const checkScrollTop = () => {
    if (window.scrollY > 400) {
      setShowScroll(true);
    } else if (window.scrollY <= 400) {
      setShowScroll(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);
  return (
    <div>
      {showScroll && (
        <button
          className="mobile-scroll-top-button"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <i className="fa fa-arrow-up"></i>
        </button>
      )}
    </div>
  );
}
