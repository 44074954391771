import React from "react";
import "./RefundPolicy.scss"; // SCSS dosyasını dahil edelim
import { Link } from "react-router-dom";

const RefundPolicy: React.FC = () => {
  return (
    <div className="refund-policy">
      <h2>İade Koşulları</h2>
      <p>
        İncelediğiniz ürün, doğrudan <strong>sonparca.com</strong> tarafından size
        kargolanacaktır. Kargo takip bilgileri tarafınıza SMS ve e-posta yoluyla
        iletilecektir.
      </p>
      <p>
        Teslim aldığınız tarihten itibaren <strong>14 gün</strong> içerisinde, iade
        koşullarına uygun olarak ürün iadesi gerçekleştirebilirsiniz.
      </p>
      <p>
        İadelerinizin kabulü için, anlaşmalı kargo firmalarımızı kullanarak ürünü
        göndermeniz gerekmektedir. Ürünün iadesi, zarar görmemiş ve
        kullanılmamış olması durumunda kabul edilecektir.
      </p>
      <p>
        İade etmek istediğiniz ürün, tarafımızca üretici ya da ithalatçı firmaya
        ulaştırılacak olup, sürecin takibi <strong>sonparca.com.tr</strong>{" "}
        tarafından yapılacaktır.
      </p>
      <p>
        Daha fazla bilgi almak için <Link to="/easy-refund">Kolay İade</Link>{" "}
        sayfamızı ziyaret edebilirsiniz.
      </p>
      <p className="refund-notice">
        <strong>Bedel İadesi:</strong> İade işleminiz tamamlandıktan sonra ödemeniz, kredi
        kartınıza veya banka hesabınıza 24 saat içinde yapılacaktır. Ödeme
        işlemlerinin hesabınıza yansıma süresi, bankanıza bağlı olarak değişiklik
        gösterebilir.
      </p>
    </div>
  );
};

export default RefundPolicy;
