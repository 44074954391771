import React from 'react'
import ProductGrid from '../../component/productGrid/ProductGrid'
import { IProduct, IProductResponse } from '../../interfaces/product'

interface IMaintainProductsProps {
  productList: IProductResponse;
  addCart: (product: IProduct) => void;
}

export default function MaintainProducts(props: IMaintainProductsProps) {
  return (
    <div>
      <ProductGrid productList={props.productList} categoryInactive addCart={props.addCart}/>
    </div>
  )
}
