import { SupportRequest } from "../../interfaces/support"
import { fetchService, serverUrl } from "./fetchService"

export async function getSupportRequests(sortAsc?: boolean, limit?: number, page?: number) {
  const path = `/Support/getSupportRequests?SortAsc=${sortAsc}&Limit=${limit}&Page=${page}`
  return await fetchService({ url: serverUrl + path, method: "GET" })
}

export async function getSupportRequest(id: number) {
  const path = `/Support/getSupportRequest?id=${id}`
  return await fetchService({ url: serverUrl + path, method: "GET" })
}

export async function createSupportRequest(params: SupportRequest) {
  const { orderId = '', productId = '', vehicleId = '', reason = '', subject = '', message = '' } = params;
  const path = `/Support/createSupportRequest?OrderId=${orderId}&ProductId=${productId}&VehicleId=${vehicleId}&Reason=${reason}&Subject=${subject}&Message=${message}`
  return await fetchService({ url: serverUrl + path, method: "PUT" })
}

export async function newSupportMessage(supportRequestId: number, message: string) {
  const path = `/Support/newSupportMessage?SupportRequestId=${supportRequestId}&Message=${message}`
  return await fetchService({ url: serverUrl + path, method: "PUT" })
}

export async function closeSupportRequest(id: number) {
  const path = `/Support/closeSupportRequest?id=${id}`
  return await fetchService({ url: serverUrl + path, method: "PUT" })
}

export async function getSupportMessages(requestId: number, sortAsc: boolean = false, limit: number = 50, skip: number= 0) {
  const path = `/Support/getSupportMessages?requestId=${requestId}&SortAsc=${sortAsc? 'true' : 'false'}&Limit=${limit?.toString()}&Skip=${skip?.toString()}`
  return await fetchService({ url: serverUrl + path, method: "GET" })
}
