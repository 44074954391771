import React from "react";
import { IOrderDetail } from "../../interfaces/order";
import OrderDetail from "../orderDetail/OrderDetail";

interface IOrderSuccessSummaryProps {
  order: IOrderDetail;
}

const OrderSuccessSummary = (props: IOrderSuccessSummaryProps) => {
  const { order } = props;
  return (
    <div>
      <div className="order-info p_relative d_block pt_45 pr_50 pb_25 pl_50 mb_50 mt_50 d-flex flex-column align-items-center">
        { 
          // tick
          <i className="fa fa-check-circle fs_40 color_green mb_50"></i>
          // tick
        }
        <h4 className="sub-title d_block fs_24 lh_30 mb_25">Siparişiniz başarılı bir şekilde alınmıştır</h4>
        <OrderDetail order={order} />
      </div>
    </div>
  );
};

export default OrderSuccessSummary;
