import React from "react";

interface InputBlockProps {
  titleTag: string;
  title: string;
  imageUrl: string;
  inputPlaceholder: string;
  inputText: string;
  searchButton: string;
  setInputText: (inputText: string) => void;
  onSearch: (searchVal: string) => void;
  description: string;
}

export default function InputBlock(props: InputBlockProps) {
  return (
    <section
      className="divider-three"
      style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(${props.imageUrl})`}}
    >
      <div className="auto-container">
        <div className="row">
          <div className="col-xl-6">
            <div className="divider-three-content">
              <div className="divider-three-content-inner">
                <span className="popular-sale">{props.titleTag}</span>
                <h2>{props.title}</h2>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="divider-three-content">
              <div className="newsletter-right-container">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.onSearch(props.inputText);
                  }}
                >
                  <input
                    type="text"
                    value={props.inputText}
                    onChange={(e) => props.setInputText(e.target.value)}
                    name="chassisNumber"
                    className="newsletter-mail"
                    placeholder={props.inputPlaceholder}
                  />
                  <button type="submit" className="theme-btn btn-style-one">
                    {props.searchButton}
                    <i className="flaticon-right-arrow"></i>
                  </button>
                </form>
                <p>{props.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
