import React, { useEffect } from "react";
import {
  deleteUserComment,
  getUserComments,
} from "../../../redux/api/commentsApi";
import { Comment } from "../../../interfaces/comment";
import Rate from "../../../component/rate/Rate";

const WishList = () => {
  const [comments, setComments] = React.useState<Comment[]>([]);
  useEffect(() => {
    getComments();
  }, []);

  const getComments = async () => {
    const comments = await getUserComments();
    setComments(comments);
  };

  const deleteComment = async (commentId: string) => {
    await deleteUserComment(commentId);
    getComments();
  };

  return (
    <div>
      <div className="row col-12">
        {comments.map((comment) => (
          <div className="card col-3 p-3 m-3" key={comment.commentId}>
            <h2>{comment.productId}</h2>
            <Rate rate={comment.rating} />
            <p>{comment.text}</p>
            <p>{comment.createdAt.toLocaleString()}</p>
            <button
              className="btn btn-danger"
              onClick={() => deleteComment(comment.commentId.toString())}
            >
              Sil
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WishList;
