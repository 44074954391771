import React from 'react';

const CommentsForm: React.FC = () => {
    return (
        <div className="comments-form-area">
            <div className="group-title p_relative d_block mb_15">
                <h3 className="fs_30 lh_40">Leave a Comment</h3>
            </div>
            <div className="form-inner">
                <form method="post" id="contact-form"> 
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input type="text" name="username" placeholder="Your Name" required aria-required="true" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input type="email" name="email" placeholder="Your email" required aria-required="true" />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <input type="text" name="phone" required placeholder="Phone" aria-required="true" />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea name="message" placeholder="Message"></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <div className="check-box">
                                <input className="check" type="checkbox" id="checkbox" />
                                <label htmlFor="checkbox">I agree that my submitted data is being collected and stored. *</label>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn mr-0">
                            <button className="theme-btn theme-btn-five" type="submit" name="submit-form">Send Message <i className="icon-4"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CommentsForm;
