// VehicleSelector.tsx
import React, { useState } from "react";
import { useVehicle } from "../../hooks/useVehicle";
import { IVehicle } from "../../interfaces/garage";
import VehicleSelectorByVin from "../vehicleSelectorByVin/VehicleSelectorByVin";

interface IProps {
  onSubmit: (vehicle: IVehicle, year: number, name: string) => void;
}

const VehicleSelector = (props: IProps) => {
  const {
    brands,
    models,
    years,
    fuels,
    engines,
    gears,
    gearBoxes,
    horsepowers,
    bodyTypes,
    vehicle,
    selections,
    setSelections,
    setVehicle,
  } = useVehicle();

  //name state'i yazılacak
  const [name, setName] = useState<string>("");

  const handleSelectionChange = (
    field: keyof typeof selections,
    value: number | string | null,
  ) => {
    setSelections((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    if (vehicle && (selections.year || vehicle.year) && name) {
      props.onSubmit(
        {
          id: vehicle.id,
          vehicleName: name,
          years: vehicle.years,
          year: selections.year || vehicle.year,
          vehicleIdentificationNumber: "",
          carStockPackageId: vehicle.carStockPackageId,
          fuelType: vehicle.fuelType,
          engine: vehicle.engine,
          gear: vehicle.gear,
          gearbox: vehicle.gearbox,
          horsepowerKw: vehicle.horsepowerKw,
          engineCode: vehicle.engineCode,
          bodyType: vehicle.bodyType,
          model: vehicle.model || ({} as any),
          images: vehicle.images,
          selections: selections,
        },
        selections.year || vehicle.year,
        name,
      );
    }
  };

  return (
    <div>
      <h2>Aracını Ekle</h2>
      <div className="col-sm-12 p-1">
      <VehicleSelectorByVin 
      vehicle={vehicle} 
      setVehicle={
        (vehicle) => {
          setVehicle(vehicle);
        }} 
      />
      </div>
      <div className="col-sm-12 p-1">
        YADA
      </div>
      <div className="row ml-1">
        <div className="col-sm-3 p-1">
          <select
            className="form-control"
            onChange={(e) =>
              handleSelectionChange("brand", Number(e.target.value))
            }
            value={selections.brand || ""}
          >
            <option value="">Marka Seçiniz</option>
            {brands?.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        {selections.brand && models && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) =>
                handleSelectionChange("model", Number(e.target.value))
              }
              value={selections.model || ""}
              disabled={!selections.brand}
            >
              <option value="">Model Seçiniz</option>
              {models?.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.model && years && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) =>
                handleSelectionChange("year", Number(e.target.value))
              }
              value={selections.year || ""}
              disabled={!selections.model}
            >
              <option value="">Yıl Seçiniz</option>
              {years?.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.year}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.year && fuels && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) => handleSelectionChange("fuel", e.target.value)}
              value={selections.fuel || ""}
              disabled={!selections.year}
            >
              <option value="">Yakıt Tipi Seçiniz</option>
              {fuels?.map((fuel) => (
                <option key={fuel.id} value={fuel.id}>
                  {fuel.id}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.fuel && engines && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) => handleSelectionChange("engine", e.target.value)}
              value={selections.engine || ""}
              disabled={!selections.fuel}
            >
              <option value="">Motor Tipi Seçiniz</option>
              {engines?.map((engine) => (
                <option key={engine.id} value={engine.id}>
                  {engine.type}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.engine && gears && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) => handleSelectionChange("gear", e.target.value)}
              value={selections.gear || ""}
              disabled={!selections.engine}
            >
              <option value="">Vites Seçiniz</option>
              {gears?.map((gear) => (
                <option key={gear.id} value={gear.id}>
                  {gear.type}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.gear && gearBoxes && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) => handleSelectionChange("gearbox", e.target.value)}
              value={selections.gearbox || ""}
              disabled={!selections.gear}
            >
              <option value="">Vites Kutusu Seçiniz</option>
              {gearBoxes?.map((gearBox) => (
                <option key={gearBox.id} value={gearBox.id}>
                  {gearBox.type}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.gearbox && horsepowers && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) =>
                handleSelectionChange("horsepower", e.target.value)
              }
              value={selections.horsepower || ""}
              disabled={!selections.gearbox}
            >
              <option value="">Beygir Gücünü Seçiniz</option>
              {horsepowers?.map((hp) => (
                <option key={hp.id} value={hp.id}>
                  {hp.horsepower}
                </option>
              ))}
            </select>
          </div>
        )}
        {selections.horsepower && bodyTypes && (
          <div className="col-sm-3 p-1">
            <select
              className="form-control"
              onChange={(e) =>
                handleSelectionChange("bodyType", e.target.value)
              }
              value={selections.bodyType || ""}
              disabled={!selections.horsepower}
            >
              <option value="">Gövde Tipini Seçiniz</option>
              {bodyTypes?.map((bodyType) => (
                <option key={bodyType.id} value={bodyType.id}>
                  {bodyType.type}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col-sm-3 p-1">
          <input
            className="form-control"
            placeholder="Araç Adı"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <button
          className="m-3 col-sm-12"
          onClick={handleSubmit}
          disabled={!selections.bodyType && !vehicle}
        >
          Ekle
        </button>
      </div>
    </div>
  );
};

export default VehicleSelector;
