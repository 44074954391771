import { IAddress } from "./address";
import { ICartItem } from "./cart";
import { IProduct } from "./product";

export interface IOrder {
  orderList: IGivenOrder[];
  paymentType?: 'Iyzico' | 'HAVALE/EFT' | 'Kapıda Ödeme' | 'Kredi Kartı';
}

export interface IGivenOrder {
  id: number;
  createdAt: string;
  status: string;
  total: number;
  items: ICartItem[];
}


export interface IShippingCompany {
  shippingCompany: string;
  deci: number;
  price: number;
}

export interface IGetOrderList{
  limit: number;
  page: number;
}

export interface IOrderDetail {
  id: number;
  status: keyof typeof OrderStatusText;
  totalPrice: number;
  createdAt: string;
  shippingAddress: IAddress;
  billingAddress: IAddress;
  orderItems: IOrderItem[];
  shippingPrice: IShippingCompany;
}
export interface IOrderItem {
  product: IProduct;
  quantity: number;
}

export const OrderStatusText = {
    ApprovalProcess: "Onay Sürecinde",
    Picking: "Onaylandı",
    SupplyControlProcess: "Tedarik Sürecinde",
    SupplyRequest: "Ödeme Bekleniyor",
    AwaitingChassisNumber: "Şase Numarası Bekleniyor",
    AwaitingInformation: "Bilgi Bekleniyor",
    Prepared: "Hazırlandı",
    Shipped: "Kargoya Verildi",
    LostInCargo: "Kargoda Zayi",
    Delivered: "Teslim Edildi",
    UnDelivered: "Teslim Edilemedi",
    Completed: "Tamamlandı",
    CancelRequest: "İptal Talebi Geldi",
    Cancelled: "İptal Edildi",
    CustomerReturned: "Müşteri Ürünü İade etti",
    InReturnLocation: "İade Konumunda",
    LostReturn: "Zayi İade",
    Returned: "İade Edildi",
    ReturnedInCargo: "Kargoda İade Edildi",
    ReturnedAfterDelivery: "Teslim Edildikten Sonra İade Edildi",
    ReturnedAfterCompletion: "Tamamlandıktan Sonra İade Edildi",
    HalfReturned: "Kısmi İade Edildi",
    Repack: "Yeniden Paketlendi",
    UnPacked: "Paketlenmedi",
    UnSupplied: "Tedarik Edilmedi"
} as const