import React from 'react';
import './MembershipAgreement.scss';

const MembershipAgreement = () => (
  <div className='membership-agreement'>
    <h1>ÜYELİK SÖZLEŞMESİ</h1>
    <h2>1. Taraflar</h2>
    <p>
      a) www.sonparca.com internet sitesinin faaliyetlerini yürüten Barbaros Hayrettin Paşa Mah. 1997 Sok. No.12/24 Esenyurt / İstanbul adresinde mukim (Bundan böyle “Sonparca” olarak anılacaktır).
    </p>
    <p>b) www.sonparca.com internet sitesine üye olan internet kullanıcısı ("Üye")</p>

    <h2>2. Sözleşmenin Konusu</h2>
    <p>
      İşbu Sözleşme’nin konusu Sonparca’nın sahip olduğu internet sitesi www.sonparca.com ‘dan üyenin faydalanma şartlarının belirlenmesidir.
    </p>

    <h2>3. Tarafların Hak ve Yükümlülükleri</h2>
    <p>
      3.1. Üye, www.sonparca.com internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin kanunlar önünde doğru olduğunu, Sonparca'nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları aynen ve derhal tazmin edeceğini beyan ve taahhüt eder.
    </p>
    <p>
      3.2. Üye, Sonparca tarafından kendisine verilmiş olan şifreyi başka kişi ya da kuruluşlara veremez, üyenin söz konusu şifreyi kullanma hakkı bizzat kendisine aittir. Bu sebeple doğabilecek tüm sorumluluk ile üçüncü kişiler veya yetkili merciler tarafından Sonparca'ya karşı ileri sürülebilecek tüm iddia ve taleplere karşı, Sonparca'nın söz konusu izinsiz kullanımdan kaynaklanan her türlü tazminat ve sair talep hakkı saklıdır.
    </p>
    <p>
      3.3. Üye www.sonparca.com internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve münhasıran üyeyi bağlayacaktır.
    </p>
    <p>
      3.4. Üye, www.sonparca.com internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının fikri ve telif haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virüs, truva atı, vb.) ve işlemlerde bulunamaz.
    </p>
    <p>
      3.5. www.sonparca.com internet sitesinde üyeler tarafından beyan edilen, yazılan, kullanılan fikir ve düşünceler, tamamen üyelerin kendi kişisel görüşleridir ve görüş sahibini bağlar. Bu görüş ve düşüncelerin Sonparca'yla hiçbir ilgi ve bağlantısı yoktur. Sonparca'nın üyenin beyan edeceği fikir ve görüşler nedeniyle üçüncü kişilerin uğrayabileceği zararlardan ve üçüncü kişilerin beyan edeceği fikir ve görüşler nedeniyle üyenin uğrayabileceği zararlardan dolayı herhangi bir sorumluluğu bulunmamaktadır.
    </p>
    <p>
      3.6. Sonparca, üye verilerinin yetkisiz kişilerce okunmasından ve üye yazılım ve verilerine gelebilecek zararlardan dolayı sorumlu olmayacaktır. Üye, www.sonparca.com internet sitesinin kullanılmasından dolayı uğrayabileceği herhangi bir zarar yüzünden Sonparca'dan tazminat talep etmemeyi peşinen kabul etmiştir.
    </p>
    <p>
      3.7. Üye, diğer internet kullanıcılarının yazılımlarına ve verilerine izinsiz olarak ulaşmamayı veya bunları kullanmamayı kabul etmiştir. Aksi takdirde, bundan doğacak hukuki ve cezai sorumluluklar tamamen üyeye aittir.
    </p>
    <p>
      3.8. İşbu üyelik sözleşmesi içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, Sonparca’yı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi halinde, Sonparca'nın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
    </p>
    <p>
      3.9. Sonparca'nın her zaman tek taraflı olarak gerektiğinde üyenin üyeliğini silme, müşteriye ait dosya, belge ve bilgileri silme hakkı vardır. Üye işbu tasarrufu önceden kabul eder. Bu durumda, Sonparca'nın hiçbir sorumluluğu yoktur.
    </p>
    <p>
      3.10. www.sonparca.com internet sitesi yazılım ve tasarımı Sonparca mülkiyetinde olup, bunlara ilişkin telif hakkı ve/veya diğer fikri mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar üye tarafından izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Bu web sitesinde adı geçen başkaca şirketler ve ürünleri sahiplerinin ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında korunmaktadır.
    </p>
    <p>
      3.11. Sonparca tarafından www.sonparca.com internet sitesinin iyileştirilmesi, geliştirilmesine yönelik olarak ve/veya yasal mevzuat çerçevesinde siteye erişmek için kullanılan İnternet servis sağlayıcısının adı ve Internet Protokol (IP) adresi, Siteye erişilen tarih ve saat, sitede bulunulan sırada erişilen sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web sitesinin Internet adresi gibi birtakım bilgiler toplanabilir.
    </p>
    <p>
      3.12. Sonparca kullanıcılarına daha iyi hizmet sunmak, ürünlerini ve hizmetlerini iyileştirmek, sitenin kullanımını kolaylaştırmak için kullanımını kullanıcılarının özel tercihlerine ve ilgi alanlarına yönelik çalışmalarda üyelerin kişisel bilgilerini kullanabilir. Sonparca, üyenin www.sonparca.com internet sitesi üzerinde yaptığı hareketlerin kaydını bulundurma hakkını saklı tutar.
    </p>
    <p>
      3.13. Sonparca’ya üye olan kişi, yürürlükte bulunan ve/veya yürürlüğe alınacak uygulamalar kapsamında Sonparca ve iştiraki olan tüm şirketler tarafından kendisine ürün ve hizmet tanıtımları, reklamlar, kampanyalar, avantajlar, anketler ve diğer müşteri memnuniyeti uygulamaları sunulmasına izin verdiğini beyan ve kabul eder. Üye, Sonparca’ya üye olurken ve/veya başka yollarla geçmişte vermiş olduğu ve/veya gelecekte vereceği kişisel ve alışveriş bilgilerinin ve alışveriş ve/veya tüketici davranış bilgilerinin yukarıdaki amaçlarla toplanmasına, Sonparca olan tüm şirketler ile paylaşılmasına, Sonparca ve iştiraki olan tüm şirketler tarafından kullanılmasına ve arşivlenmesine izin verdiğini beyan ve kabul eder. Üye aksini bildirmediği sürece üyeliği sona erdiğinde de verilerin toplanmasına, iştiraki olan tüm şirketler ile paylaşılmasına, Sonparca ve iştiraki olan tüm şirketler tarafından kullanılmasına ve arşivlenmesine izin verdiğini beyan ve kabul eder. Üye aksini bildirmediği sürece Sonparca ve iştiraki olan tüm şirketlerin kendisi ile internet, telefon, SMS, vb iletişim kanalları kullanarak irtibata geçmesine izin verdiğini beyan ve kabul eder. Üye yukarıda bahsi geçen bilgilerin toplanması, paylaşılması, kullanılması, arşivlenmesi ve kendisine erişilmesi nedeniyle doğrudan ve/veya dolaylı maddi ve/veya manevi menfi ve/veya müsbet, velhasıl herhangi bir zarara uğradığı konusunda talepte bulunmayacağını ve Sonparca ve iştiraki olan şirketleri sorumlu tutmayacağını beyan ve kabul eder. Üye veri paylaşım tercihlerini değiştirmek isterse, bu talebini Sonparca’nın müşteri hizmetleri çağrı merkezlerine iletebilir.
    </p>
    <p>
      3.14. Sonparca, üyenin kişisel bilgilerini yasal bir zorunluluk olarak istendiğinde veya (a) yasal gereklere uygun hareket etmek veya Sonparca’ya tebliğ edilen yasal işlemlere uymak; (b) Sonparca ve Sonparca web sitesi ailesinin haklarını ve mülkiyetini korumak ve savunmak için gerekli olduğuna iyi niyetle kanaat getirdiği hallerde açıklayabilir.
    </p>
    <p>
      3.15. Sonparca web sitesinin virus ve benzeri amaçlı yazılımlardan arındırılmış olması için mevcut imkanlar dahilinde tedbir alınmıştır. Bunun yanında nihai güvenliğin sağlanması için kullanıcının, kendi virus koruma sistemini tedarik etmesi ve gerekli korunmayı sağlaması gerekmektedir. Bu bağlamda üye Sonparca web sitesi'ne girmesiyle, kendi yazılım ve işletim sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya da dolaylı sonuçlarından kendisinin sorumlu olduğunu kabul etmiş sayılır.
    </p>
    <p>
      3.16. Sonparca, sitenin içeriğini dilediği zaman değiştirme, kullanıcılara sağlanan herhangi bir hizmeti değiştirme ya da sona erdirme veya Sonparca web sitesi'nde kayıtlı kullanıcı bilgi ve verilerini silme hakkını saklı tutar.
    </p>
    <p>
      3.17. Sonparca, üyelik sözleşmesinin koşullarını hiçbir şekil ve surette ön ihbara ve/veya ihtara gerek kalmaksızın her zaman değiştirebilir, güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen ya da yürürlükten kaldırılan her hüküm , yayın tarihinde tüm üyeler bakımından hüküm ifade edecektir.
    </p>
    <p>
      3.18. Taraflar, Sonparca'ya ait tüm bilgisayar kayıtlarının tek ve gerçek münhasır delil olarak, HUMK madde 287'ye uygun şekilde esas alınacağını ve söz konusu kayıtların bir delil sözleşmesi teşkil ettiği hususunu kabul ve beyan eder.
    </p>
    <p>
      3.19. Sonparca, iş bu üyelik sözleşmesi uyarınca, üyelerinin kendisinde kayıtlı elektronik posta adreslerine bilgilendirme mailleri ve cep telefonlarına bilgilendirme SMS’leri gönderme yetkisine sahip olmakla beraber, üye işbu üyelik sözleşmesini onaylamasıyla beraber bilgilendirme maillerinin elektronik posta adresine ve bilgilendirme SMS’lerinin cep telefonuna gönderilmesini kabul etmiş sayılacaktır. Üye mail ve/veya SMS almaktan vazgeçmek istemesi durumunda “Hesabım” bölümündeki “Üyelik Bilgilerim” kısmından mail ve/veya SMS gönderim iptal işlemini gerçekleştirebilecektir.
    </p>

    <h2>4. Sözleşmenin Feshi</h2>
    <p>
      İşbu sözleşme üyenin üyeliğini iptal etmesi veya Sonparca tarafından üyeliğinin iptal edilmesine kadar yürürlükte kalacaktır. Sonparca, üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal etmesi durumunda üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı olarak feshedebilecektir.
    </p>

    <h2>5. İhtilaflerin Halli</h2>
    <p>
      İşbu sözleşmeye ilişkin ihtilaflerde İstanbul Mahkemeleri ve İcra Daireleri yetkilidir.
    </p>

    <h2>6. Yürürlük</h2>
    <p>
      Üyenin, üyelik kaydı yapması üyenin üyelik sözleşmesinde yer alan tüm maddeleri okuduğu ve üyelik sözleşmesinde yer alan maddeleri kabul ettiği anlamına gelir. İşbu Sözleşme üyenin üye olması anında akdedilmiş ve karşılıklı olarak yürürlüğe girmiştir.
    </p>
  </div>
);

export default MembershipAgreement;
