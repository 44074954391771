import { BankInstallmentOptions } from '../../interfaces/payment';
import './InstallmentTable.scss';

// Props for the component
interface InstallmentTableProps {
  data: BankInstallmentOptions[];
}

export const InstallmentTable: React.FC<InstallmentTableProps> = ({ data }) => {
  return (
    <div className="installment-table">
      {data.map((bank) => (
        <div key={bank.bankName} className="bank-section">
          <h3>{bank.bankName}</h3>
          {bank.force3DSecure && <p>3D Secure is mandatory for this bank.</p>}
          <table className="installment-table">
            <thead>
              <tr>
                <th>Taksit Sayısı</th>
                <th>Taksit Tutarı (TL)</th>
                <th>Toplam Tutar (TL)</th>
              </tr>
            </thead>
            <tbody>
              {bank.installmentOptions.map((option:any) => (
                <tr key={option.installmentCount}>
                  <td>{option.installmentCount}</td>
                  <td>{option.installmentAmount.toFixed(2)}</td>
                  <td>{option.totalAmount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};