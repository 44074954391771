import React from "react";
import "./Order.scss";
import "../cartBody/Cart.scss";
import OrderPaymentSummary from "./OrderPaymentSummary";
import { useSelector } from "react-redux";
import {
  selectPaymentType,
  setPaymentType,
} from "../../redux/slices/orderSlice";
import { useAppDispatch } from "../../redux/hooks";
import { IOrderDetail, IShippingCompany } from "../../interfaces/order";
import { selectAddressList } from "../../redux/slices/addressSlice";
import {
  getCurrentOrder,
  setOrderBillingAddress,
  setOrderShippingAddress,
  setShippingCompany,
} from "../../redux/api/orderApi";
import { toast } from "react-toastify";
import Modal from "../modal/Modal";
import PaymentForm from "./PaymentForm";
import {
  createEftHavalePayment,
  createIyzicoCreditCardPayment,
  createIyzicoPayment,
  createOnDoorPayment,
} from "../../redux/api/paymentApi";
import { check } from "prettier";

interface CartItem {
  id: number;
  price: number;
  productName: string;
  quantity: number;
  showQuantity?: boolean;
}

interface OrderBodyProps {
  cartItems: CartItem[];
  shippingCompanyList: IShippingCompany[];
  setSuccessfulOrder: (value: IOrderDetail) => void;
}

export default function OrderBody(props: OrderBodyProps) {
  const dispatch = useAppDispatch();
  const [isBillingAddressSame, setIsBillingAddressSame] = React.useState(true);
  const [show3DSecurityModal, setShow3DSecurityModal] = React.useState(false);
  const [checkPaymentStatus, setCheckPaymentStatus] = React.useState(false);
  const [formContent, setFormContent] = React.useState<string>("");
  const paymentType = useSelector(selectPaymentType);
  const addressList = useSelector(selectAddressList);
  const [selectedAddressIdx, setSelectedAddressIdx] = React.useState<number>(0);
  const [selectedBillingAddressIdx, setSelectedBillingAddressIdx] =
    React.useState<number>(0);
  const [selectedShippingCompanyIdx, setSelectedShippingCompanyIdx] =
    React.useState<number>(0);
  const [creditCard, setCreditCard] = React.useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });
  const cartItems = [
    ...props.cartItems,
    {
      id: 0,
      price:
        props.shippingCompanyList?.[selectedShippingCompanyIdx]?.price || 0,
      productName:
        props.shippingCompanyList?.[selectedShippingCompanyIdx]
          ?.shippingCompany,
      quantity: 1,
      showQuantity: false,
    } as CartItem,
  ];
  const handleSelectedAddress = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const idx = addressList.findIndex(
      (address) => address.id === parseInt(e.target.value),
    );
    await setOrderShippingAddress(parseInt(e.target.value));
    setSelectedAddressIdx(idx);
  };
  const handleSelectedBillingAddress = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const idx = addressList.findIndex(
      (address) => address.id === parseInt(e.target.value),
    );
    await setOrderBillingAddress(parseInt(e.target.value));
    setSelectedBillingAddressIdx(idx);
  };

  const handleSelectedShippingCompany = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const idx = props.shippingCompanyList?.findIndex(
      (company) => company.shippingCompany === e.target.value,
    );
    await setShippingCompany(e.target.value);
    setSelectedShippingCompanyIdx(idx);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPaymentType(e.target.value));
  };

  const handleCheckPaymentStatus = async () => {
    if (checkPaymentStatus) {
      return;
    }
    let tryCount = 0;
    const interval = setInterval(async () => {
      setCheckPaymentStatus(true);
      if (tryCount > 120) {
        clearInterval(interval);
        setShow3DSecurityModal(false);
        toast("Ödeme işlemi başarısız oldu", { type: "error" });
        return;
      }
      tryCount++;
      const orderResponse = await getCurrentOrder();
      if (!orderResponse) {
        return;
      }
      console.log(orderResponse);
      if (orderResponse?.status === "Picking") {
        clearInterval(interval);
        setShow3DSecurityModal(false);
        props.setSuccessfulOrder(orderResponse.order);
      }
    }, 5000);
  };

  const complatePayment = async () => {
    if (paymentType === "Iyzico") {
      const response = await createIyzicoPayment();
      if (response) {
        setFormContent(response.paymentUrl);
        setShow3DSecurityModal(true);
        handleCheckPaymentStatus();
      }
    }
    if (paymentType === "Kredi Kartı") {
      const response = await createIyzicoCreditCardPayment();
      setShow3DSecurityModal(true);
      handleCheckPaymentStatus();
    }
    if (paymentType === "Kapıda Ödeme"){
      const response = await createOnDoorPayment();
      props.setSuccessfulOrder(response.order);
    }
    if (paymentType === "HAVALE/EFT") {
      const response = await createEftHavalePayment();
      props.setSuccessfulOrder(response.order);
    }
  };

  const handleSubmitOrder = async () => {
    // Siparişi tamamla butonuna basıldığında burası çalışacak
    if (selectedAddressIdx === undefined) {
      toast("Lütfen bir adres seçiniz", { type: "warning" });
      return;
    }
    if (!paymentType) {
      toast("Lütfen bir ödeme türü seçiniz", { type: "warning" });
      return;
    }
    if (selectedShippingCompanyIdx === undefined) {
      toast("Lütfen bir kargo firması seçiniz", { type: "warning" });
      return;
    }
    if (!isBillingAddressSame && selectedBillingAddressIdx === undefined) {
      toast("Lütfen bir fatura adresi seçiniz", { type: "warning" });
      return;
    }
    await setOrderShippingAddress(addressList[selectedAddressIdx].id);
    await setOrderBillingAddress(
      isBillingAddressSame
        ? addressList[selectedAddressIdx].id!
        : addressList[selectedBillingAddressIdx].id!,
    );
    await setShippingCompany(
      props.shippingCompanyList[selectedShippingCompanyIdx].shippingCompany,
    );
    await complatePayment();
  };

  return (
    <section className="checkout-section p_relative pt_50 pb_150">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 left-column">
            <div className="inner-box">
              <div className="billing-info p_relative d_block mb_55">
                <h4 className="sub-title d_block fs_30 lh_40 mb_25">
                  Sipariş Adresi
                </h4>
                <div className="billing-form p_relative d_block">
                  <select
                    className="form-control"
                    onChange={handleSelectedAddress}
                  >
                    <option value={undefined}>Adres Seçiniz</option>
                    {addressList.map((address) => (
                      <option
                        key={address.id}
                        value={address.id}
                        selected={
                          address.id === addressList[selectedAddressIdx]?.id
                        }
                      >
                        {address.addressName}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Fatura adresi ile sipariş adresi aynı adres olsun mu check box ı*/}
                <div className="checkbox p_relative d_block">
                  <div className="option-block pb_12 mb_13">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        name="checkbox1"
                        checked={isBillingAddressSame}
                        onChange={() =>
                          setIsBillingAddressSame(!isBillingAddressSame)
                        }
                      />
                      <label htmlFor="checkbox1">
                        Fatura adresi ile sipariş adresi aynı olsun
                      </label>
                    </div>
                  </div>
                </div>
                {/* Eğer adres seçildiyse adresin bilgileri kartı gösterilir label olacak şekilde */}
                {selectedAddressIdx !== undefined && (
                  <div className="billing-info p_relative d_block mb_55">
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 col-md-6">
                        <h6>Kargo Adresi</h6>
                        <p>{addressList?.[selectedAddressIdx]?.name}</p>
                        <p>{addressList?.[selectedAddressIdx]?.addressText}</p>
                        <p>
                          Telefon: {addressList?.[selectedAddressIdx]?.phone}
                        </p>
                        <p>Email: {addressList?.[selectedAddressIdx]?.email}</p>
                      </div>
                      {isBillingAddressSame && (
                        <div className="col-sm-12 col-lg-6 col-md-6">
                          <h6>Fatura Adresi</h6>
                          <p>
                            {addressList?.[selectedBillingAddressIdx]?.name}
                          </p>
                          <p>
                            {
                              addressList?.[selectedBillingAddressIdx]
                                ?.addressText
                            }
                          </p>
                          <p>
                            Telefon:{" "}
                            {addressList?.[selectedBillingAddressIdx]?.phone}
                          </p>
                          <p>
                            Email:{" "}
                            {addressList?.[selectedBillingAddressIdx]?.email}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {
                // Fatura adresi ile aynıysa addressList'ten bir adres seçilir
                !isBillingAddressSame && (
                  <div className="billing-info p_relative d_block mb_55">
                    <h4 className="sub-title d_block fs_30 lh_40 mb_25">
                      Fatura Adresi
                    </h4>
                    <div className="billing-form p_relative d_block">
                      <div>
                        <select
                          className="form-control"
                          onChange={handleSelectedBillingAddress}
                        >
                          <option value={undefined}>
                            Fatura Adresi Seçiniz
                          </option>
                          {addressList.map((address) => (
                            <option
                              key={address.id}
                              value={address.id}
                              selected={
                                address.id ===
                                addressList[selectedBillingAddressIdx]?.id
                              }
                            >
                              {address.addressName}
                            </option>
                          ))}
                        </select>
                      </div>
                      {!isBillingAddressSame &&
                        selectedBillingAddressIdx !== undefined && (
                          <div className="row mt_13 pt_13">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                              <h6>Fatura Adresi</h6>
                              <p>
                                {addressList?.[selectedBillingAddressIdx]?.name}
                              </p>
                              <p>
                                {
                                  addressList?.[selectedBillingAddressIdx]
                                    ?.addressText
                                }
                              </p>
                              <p>
                                Telefon:{" "}
                                {
                                  addressList?.[selectedBillingAddressIdx]
                                    ?.phone
                                }
                              </p>
                              <p>
                                Email:{" "}
                                {
                                  addressList?.[selectedBillingAddressIdx]
                                    ?.email
                                }
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )
              }
              <div className="inner-box">
                <div className="billing-info p_relative d_block mb_55">
                  <h4 className="sub-title d_block fs_30 lh_40 mb_25">
                    Kargo Firması Seçiniz
                  </h4>
                  <select
                    className="form-control"
                    onChange={handleSelectedShippingCompany}
                  >
                    <option value={undefined}>Kargo Firması Seçiniz</option>
                    {props.shippingCompanyList?.map((company) => (
                      <option
                        key={company.shippingCompany}
                        value={company.shippingCompany}
                        selected={
                          company.shippingCompany ===
                          props.shippingCompanyList?.[
                            selectedShippingCompanyIdx
                          ]?.shippingCompany
                        }
                      >
                        {`${company.shippingCompany} - ${company.price.toFixed(2)} TL`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="additional-info">
                <div className="note-book p_relative d_block">
                  <label className="p_relative d_block fs_16 font_family_poppins color_black mb_2">
                    Sipariş Notu
                  </label>
                  <textarea
                    name="note_box"
                    placeholder="Siparişinizle ilgili notlarınızı buraya yazabilirsiniz."
                  ></textarea>
                </div>
              </div>
              {paymentType === "Kredi Kartı" && (
                <PaymentForm
                  creditCard={creditCard}
                  setCreditCard={setCreditCard}
                />
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 right-column">
            <div className="inner-box">
              <OrderPaymentSummary
                paymentTitle={"Sipariş Özeti"}
                paymentTotalTitle={"Sipariş Toplamı"}
                cartItems={cartItems}
              />
              <div className="payment-info p_relative d_block pt_45 pr_50 pb_50 pl_50">
                <h4 className="sub-title d_block fs_24 lh_30 mb_40">
                  Ödeme Türü Seçiniz
                </h4>
                <div className="payment-inner p_relative d_block pt_25 pr_30 pb_20 pl_30 mb_30">
                  <div className="option-block pb_12 mb_13">
                    <div className="check-box">
                      <input
                        className="check"
                        type="checkbox"
                        value={"Kredi Kartı"}
                        id="checkbox5"
                        onChange={handleCheckboxChange}
                        checked={paymentType === "Kredi Kartı"}
                      />
                      <label
                        htmlFor="checkbox5"
                        className="fs_16 fw_medium font_family_inter color_black"
                      >
                        Kredi Kartı ile Ödeme
                      </label>
                    </div>
                    <p className="fs_14 font_family_poppins pl_30">
                      Kredi kartı ile ödeme yapmak için lütfen tıklayınız.
                    </p>
                  </div>
                  <div className="option-block pb_12 mb_13">
                    <div className="check-box">
                      <input
                        className="check"
                        type="checkbox"
                        id="checkbox2"
                        value={"HAVALE/EFT"}
                        onChange={handleCheckboxChange}
                        checked={paymentType === "HAVALE/EFT"}
                      />
                      <label
                        htmlFor="checkbox2"
                        className="fs_16 fw_medium font_family_inter color_black"
                      >
                        Havale/EFT ile Ödeme
                      </label>
                    </div>
                    <p className="fs_14 font_family_poppins pl_30">
                      Hesap Adı: PARÇA DUNYASI IBAN: TRXXXX QNB FİNANSBANK A.Ş.
                      Şube Kodu: 5555 Hesap No: 55555
                    </p>
                    <p className="fs_14 font_family_poppins pl_30">
                      <b>Havale/Eft Yaparken Dikkat Edilmesi Gerekenler:</b>
                      <ul className="">
                        <li>Alıcı ismi mutlaka "PARCADUNYASI" olmalıdır.</li>
                        <li>Açıklama kısmına sipariş numaranızı yazınız.</li>
                        <li>
                          1 iş günü(24 saat) içerisinde ödemesi gerçekleşmeyen
                          siparişler otomatik olarak iptal edilmektedir.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="option-block clearfix d-flex flex-column">
                    <div>
                      <div className="check-box pull-left mr_25">
                        <input
                          className="check"
                          type="checkbox"
                          value={"Iyzico"}
                          id="checkbox3"
                          onChange={handleCheckboxChange}
                          checked={paymentType === "Iyzico"}
                        />
                        <label
                          htmlFor="checkbox3"
                          className="fs_16 fw_medium font_family_inter color_black"
                        >
                          Iyzico ile Ödeme
                        </label>
                      </div>
                      <div className="link pull-left">
                        <a
                          href="checkout.html"
                          className="fs_16 fw_medium font_family_inter color_black"
                        >
                          Iyzico Nedir?
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className="fs_14 font_family_poppins pl_30">
                        Iyzico ile ödeme yapmak için lütfen tıklayınız.
                      </p>
                    </div>
                  </div>
                  <div className="option-block pb_12 mb_13">
                    <div className="check-box">
                      <input
                        className="check"
                        type="checkbox"
                        value={"Kapıda Ödeme"}
                        id="checkbox4"
                        onChange={handleCheckboxChange}
                        checked={paymentType === "Kapıda Ödeme"}
                      />
                      <label
                        htmlFor="checkbox4"
                        className="fs_16 fw_medium font_family_inter color_black"
                      >
                        Kapıda Ödeme
                      </label>
                    </div>
                    <p className="fs_14 font_family_poppins pl_30">
                      Kapıda ödeme seçeneği ile siparişinizi teslim alırken
                      ödeme yapabilirsiniz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-link mt_50">
              <button
                onClick={handleSubmitOrder}
                className="theme-btn theme-btn-eight"
              >
                Siparişi Tamamla <i className="icon-4"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {show3DSecurityModal && (
        <Modal
          show={show3DSecurityModal}
          setShowModal={setShow3DSecurityModal}
          modalTitle="Güvenli Ödeme"
          saveAndClose={() => {
            toast("Ödeme devam etmektedir.");
          }}
          showButtons={false}
        >
          <iframe
            title="Güvenli Ödeme"
            className="order-iframe"
            src={`${formContent}&iframe=true`}
            allow="fullscreen"
          />
        </Modal>
      )}
    </section>
  );
}
