import React from 'react'

export default function SearchPopup() {
  return (
    <div id="search-popup" className="search-popup">
            <div className="popup-inner">
                <div className="upper-box clearfix">
                    <figure className="logo-box pull-left"><a href="index.html"><img src="/assets/images/logo.png" alt="logo"/></a></figure>
                    <div className="close-search pull-right"><img src="/assets/images/icons/close.png" alt="close"/></div>
                </div>
                <div className="overlay-layer"></div>
                <div className="auto-container">
                    <div className="search-form">
                        <form method="post" action="index.html">
                            <div className="form-group">
                                <fieldset>
                                    <input type="search" className="form-control" name="search-input" value="" placeholder="Type your keyword and hit" required />
                                    <button><i className="far fa-search"></i></button>
                                </fieldset>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
  )
}
