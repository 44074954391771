import React from 'react';
import './MembershipAgreement.scss'

const PersonalDataInformation = () => {
  return (
    <p className='membership-agreement'>
          <strong>
            Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni
          </strong>
          <br />
          <div>
            <h2>Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni</h2>
            <p>
              Şirketimiz Sonparca.com ("Sonparca" ve/veya "Şirket"),
              müşterilere/üyelere ilişkin kişisel veriler bakımından 6698 sayılı
              Kişisel Verilerin Korunması Kanunu ("Kanun") kapsamında "veri
              sorumlusu" sıfatına sahip olup işbu Aydınlatma Metni ile söz
              konusu Kanun uyarınca müşterilerimiz Sonparca tarafından
              gerçekleştirilen kişisel veri işleme faaliyetleri hakkında
              aydınlatılmaktadır.
            </p>

            <h3>Veri Sorumlusu İletişim Bilgileri</h3>
            <p>
              Veri sorumlusu olarak Sonparca.com'un iletişim bilgileri şu
              şekildedir:
            </p>
            <ul>
              <li>
                <strong>Adresi:</strong> Barbaros Hayrettin Paşa Mah. 1997 Sok.
                No.12/24 Esenyurt/İSTANBUL
              </li>
              <li>
                <strong>Web sitesi:</strong>{" "}
                <a href="https://www.sonparca.com">www.sonparca.com</a>
              </li>
              <li>
                <strong>Telefon numarası:</strong> 0850 840 34 00
              </li>
            </ul>

            <h3>Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi</h3>
            <p>
              Kişisel verileriniz (kimlik, iletişim, araç bilgileri, işlem
              güvenliği, pazarlama verileri),{" "}
              <a href="https://www.sonparca.com">www.sonparca.com</a> internet
              sitemizi kullanmanız, internet sitemize üye olmanız ve üye olarak
              alışveriş yapmanız durumunda otomatik yöntemlerle toplanmaktadır.
              Kişisel verileriniz internet sitemize üyelik kaydı oluştururken ve
              6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve
              ilgili Yönetmelik hükümleri uyarınca elektronik ticari ileti onayı
              vermeniz durumunda açık rızanız kapsamında işlenmekte, üye olarak
              sipariş verdiğiniz ürünlerin tarafınıza teslimi ve fatura
              düzenlenmesi için kimlik ve adres bilgileriniz sözleşmeden doğan
              edimlerin ifası ile hukuki yükümlülüğümüzün yerine getirilmesi
              hukuki sebebine dayalı olarak işlenmektedir.
            </p>

            <h3>Kişisel Verilerin İşlenme Amacı</h3>
            <p>
              Kişisel verileriniz, üyelik kaydı ve hesabının oluşturulması, buna
              ilişkin kayıtların tutulması, Sonparca tarafından sunulan ürün ve
              hizmetlerden ilgili kişilerin faydalanmasını sağlamak ve yürütülen
              ticari faaliyetlerin gerçekleştirilmesi için gerekli çalışmaların
              iş birimleri tarafından yapılması ve ilgili iş süreçlerinin
              yürütülmesi, Sonparca'nın ticari ve/veya iş stratejilerinin
              planlanması ve icrası,{" "}
              <a href="https://www.sonparca.com">www.sonparca.com</a> üzerinden
              sipariş verilen ürünlerin teslimi, 6563 Sayılı Elektronik
              Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili Yönetmelik
              hükümleri uyarınca onay vermeniz durumunda müşteri anketi,
              promosyon, tanıtım, kampanyalar başta olmak üzere telefon, çağrı
              merkezi, otomatik arama makineleri, akıllı ses kaydedici
              sistemler, elektronik posta, kısa mesaj hizmeti gibi vasıtalar
              kullanılarak elektronik ortamda ticari amaçlarla veri, ses ve
              görüntü içerikli iletiler gönderilmesi amacıyla işlenmektedir.
            </p>

            <h3>Kişisel Verilerin Aktarımı</h3>
            <p>
              Kişisel verileriniz yurtiçi ve yurtdışında bulunan, iş
              ortaklarımız, tedarikçilerimiz, barındırma hizmet sağlayıcıları
              (hosting servisleri), kanunen yetkili kamu kurum ve kuruluşları
              ile özel kurumlar ile paylaşılabilecektir.
            </p>

            <h3>Kişisel Verilerinize İlişkin Haklarınız</h3>
            <p>Kanun’un 11. maddesi uyarınca ilgili kişiler;</p>
            <ul>
              <li>
                (i) kendileri ile ilgili kişisel veri işlenip işlenmediğini
                öğrenme,
              </li>
              <li>
                (ii) kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li>
                (iii) kişisel verilerin işlenme amacını ve bunların amacına
                uygun kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                (iv) yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                (v) kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
                isteme,
              </li>
              <li>
                (vi) Kanun ve ilgili diğer kanun hükümlerine uygun olarak
                işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
                edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              </li>
              <li>
                (vii) işlenen verilerin münhasıran otomatik sistemler
                vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine
                bir sonucun ortaya çıkmasına itiraz etme ve
              </li>
              <li>
                (viii) kişisel verilerin kanuna aykırı olarak işlenmesi
                sebebiyle zarara uğraması hâlinde zararın giderilmesini talep
                etme haklarına sahiptir.
              </li>
            </ul>
            <p>
              İlgili kişi olarak taleplerinizi, “Veri Sorumlusuna Başvuru Usul
              ve Esasları Hakkında Tebliğe” göre Şirketimize iletebilirsiniz.
            </p>
          </div>
        </p>
  );
};

export default PersonalDataInformation;