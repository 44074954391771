import React from "react";
import CategoryBlock from "../../component/categoryBlock/CategoryBlock";
import { useSelector } from "react-redux";
import { selectCategories } from "../../redux/slices/productSlice";

export default function HomeCategoryBlock() {
  const categories = useSelector(selectCategories);
  return (
    <div>
      <CategoryBlock categories={categories} />
    </div>
  );
}
