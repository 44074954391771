import React from 'react';

interface ContentTwoProps {
    heading: string;
    paragraph1: string;
    images: string[];
    quote: {
        text: string;
        author: string;
    };
    paragraph2: string;
}

const ContentTwo: React.FC<ContentTwoProps> = ({ heading, paragraph1, images, quote, paragraph2 }) => {
    return (
        <div className="content-two p_relative d_block mb_30">
            <div className="text p_relative d_block mb_30">
                <h3 className="d_block fs_30 lh_30 mb_30">{heading}</h3>
                <p className="font_family_poppins">{paragraph1}</p>
            </div>
            <div className="image-box p_relative d_block mb_65">
                <div className="row clearfix">
                    {images.map((image, index) => (
                        <div className="col-lg-6 col-md-6 col-sm-12 image-column" key={index}>
                            <figure className="image p_relative d_block b_radius_5"><img src={image} alt="blog" /></figure>
                        </div>
                    ))}
                </div>
            </div>
            <div className="text">
                <p className="font_family_poppins mb_30">{paragraph2}</p>
                <blockquote className="p_relative d_block pt_30 pr_160 p-5 b_radius_5 b_shadow_6 m_0">
                    <h5 className="d_block fs_18 fw_normal lh_28 mb_5">{quote.text}</h5>
                    <h5 className="name p_relative d_iblock fs_18 fw_sbold pl_25">{quote.author}</h5>
                </blockquote>
            </div>
        </div>
    );
};

export default ContentTwo;
