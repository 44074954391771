import React, { useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import MyVehicleSelect from "./MyVehicleSelect";
import MaintainProducts from "./MaintainProducts";
import { IProduct, IProductResponse } from "../../interfaces/product";
import OrderPaymentSummary from "../../component/orderBody/OrderPaymentSummary";
import { addCartAsync } from "../../redux/slices/cartSlice";
import { useAppDispatch } from "../../redux/hooks";
import { IMyVehicle, IVehicle } from "../../interfaces/garage";
import { useSelector } from "react-redux";
import {
  fetchVehiclesAsync,
  selectSelectedMyVehicle,
  selectVehicles,
  setSelectedMyVehicle,
  setSelectedVehicle,
} from "../../redux/slices/garageSlice";
import { fetchProducts } from "../../redux/api/productApi";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/slices/userSlice";
import BannerVehicleSelect from "../../component/banner/BannerVehicleSelect";
import {
  clearSelectedCategory,
  selectSelectedCategory,
} from "../../redux/slices/productSlice";
import SelectedVehicle from "../../component/selectedVehicle/SelectedVehicle";
import { ICartItem } from "../../interfaces/cart";
import PageTitle from "../../component/pageTitle/PageTitle";

const steps = [
  { label: "Hava Filtresi", categoryIds: [12] },
  { label: "Yağ Filtresi", categoryIds: [16] },
  { label: "Polen Filtresi", categoryIds: [14] },
  { label: "Yakıt Filtresi", categoryIds: [18, 21] },
  { label: "Motor Yağı", categoryIds: [388] },
];

export default function MaintainRobot() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const isUserAuth = !!user?.user;
  const [currentStep, setCurrentStep] = useState(0);
  const [productList, setProductList] = useState<IProductResponse>(
    {} as IProductResponse,
  );
  const [selectedCarts, setSelectedCarts] = useState<ICartItem[]>([]);
  const [stepList, setStepList] = useState<Array<any>>([]);
  const vehicles = useSelector(selectVehicles);
  const selectedMyVehicle = useSelector(selectSelectedMyVehicle);
  const selectedCartItems = selectedCarts.map((cart) => ({
    id: cart.product.id,
    price: cart.product.price,
    productName: cart.product.brand.name + " " + cart.product.name,
    quantity: cart.quantity,
  }));

  const changeSelectedVehicleFetchProduct = async () => {
    if (selectedMyVehicle) {
      fetchProducts({
        sortAsc: false,
        limit: 12,
        page: 1,
        slug: "",
        vehicleId: selectedMyVehicle?.vehicle?.id + "",
      }).then((response) => {
        setProductList(response);
        const productCategories = response.items.map(
          (product: IProduct) => product.categoryId,
        );
        setStepList([
          { label: "Araç Seçimi" },
          ...steps.filter((step) =>
            step.categoryIds?.some((categoryId) =>
              productCategories.includes(categoryId),
            ),
          ),
        ]);
      });
    }
  };

  useEffect(() => {
    dispatch(clearSelectedCategory());
  }, []);

  useEffect(() => {
    if (isUserAuth && (!vehicles || vehicles.length === 0)) {
      dispatch(fetchVehiclesAsync());
    }

    changeSelectedVehicleFetchProduct();
  }, [selectedMyVehicle, selectedMyVehicle?.id]);

  const setMyVehicle = (vehicle: IMyVehicle) => {
    dispatch(setSelectedMyVehicle(vehicle));
    changeSelectedVehicleFetchProduct();
  };
  const setVehicle = (vehicle: IVehicle) => {
    dispatch(setSelectedVehicle(vehicle));
  };
  const nextStep = async () => {
    if (currentStep === 0 && !selectedMyVehicle?.vehicle?.id) {
      return;
    }
    if (currentStep === stepList.length - 1) {
      selectedCarts.forEach(async (cart) => {
        await dispatch(
          addCartAsync({
            product: cart.product,
            options: [],
            price: cart.product.price,
            quantity: cart.quantity,
            total: cart.product.price,
          }),
        );
      });
      navigate("/cart");
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };

  const filteredProductListItems = productList?.items?.filter(
    (product) =>
      product.categoryId &&
      stepList[currentStep]?.categoryIds?.includes(product.categoryId),
  );

  return (
    <div>
      <PageTitle title="Bakım Robotu" />

      {currentStep === 0 ? (
        <div className="container col-xl-3 col-md-8 col-sm-12 mt_50 mb_50">
          <p className="maintenance-robot-description mb_50">
            Bakım Robotu, araç bakımınızı kolaylaştırmak ve optimize etmek için
            tasarlanmış yenilikçi bir dijital asistandır. Aracınızın modelini ve
            yılını seçerek başlayın, ardından size özel bakım önerilerini adım
            adım takip edin. Bakım Robotu, aracınızın ihtiyaçlarına göre
            özelleştirilmiş ürün ve hizmetleri sunar, böylece aracınızın
            performansını en üst düzeyde tutmanıza yardımcı olur. Düzenli bakım
            planlaması, parça değişimi hatırlatmaları ve uzman tavsiyeleriyle,
            Bakım Robotu aracınızın ömrünü uzatmanın ve bakım maliyetlerini
            düşürmenin akıllı yoludur.
          </p>
          <h2 className="text-center mb-4">Araç Seçiniz</h2>
          {isUserAuth ? (
            <MyVehicleSelect
              vehicleList={vehicles}
              selectedVehicle={selectedMyVehicle}
              setSelectedVehicle={setMyVehicle}
            />
          ) : (
            <BannerVehicleSelect
              onSubmit={(vehicle, year) => {
                vehicle.year = year;
                setVehicle(vehicle);
              }}
            />
          )}
          {selectedMyVehicle && selectedMyVehicle?.id && (
            <div className="pb-3">
              <SelectedVehicle myVehicle={selectedMyVehicle} />
            </div>
          )}
          {selectedMyVehicle && selectedMyVehicle?.id && (
            <div className="d-flex justify-content-end">
              {stepList.length < 2 ? (
                <div className="text-center">
                  <p>
                    Bu aracın bakım ürünleri bulunmamaktadır. Lütfen başka bir
                    araç seçiniz.
                  </p>
                </div>
              ) : (
                <>
                  {selectedMyVehicle && selectedMyVehicle?.id && (
                    <button
                      className="btn"
                      type="submit"
                      onClick={() => setCurrentStep(1)}
                    >
                      {"İleri"}
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          <Stepper
            steps={stepList}
            activeStep={currentStep}
            onClick={(e) => console.log(e)}
          ></Stepper>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12">
                  {currentStep !== 0 && (
                    <MaintainProducts
                      productList={{
                        ...productList,
                        items: filteredProductListItems,
                        totalCount: filteredProductListItems.length,
                      }}
                      addCart={(product) => {
                        if (
                          selectedCarts.find(
                            (cart) => cart.product.id === product.id,
                          )
                        ) {
                          setSelectedCarts((prev) =>
                            prev.map((cart) =>
                              cart.product.id === product.id
                                ? { ...cart, quantity: cart.quantity + 1 }
                                : cart,
                            ),
                          );
                          return;
                        }
                        setSelectedCarts((prev) => [
                          ...prev,
                          {
                            product: product,
                            quantity: 1,
                            options: [],
                            total: product.price,
                            id: product.id,
                            price: product.price,
                          },
                        ]);
                      }}
                    />
                  )}
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 right-column">
                  {selectedMyVehicle && selectedMyVehicle?.id && (
                    <div className="pb-3">
                      <SelectedVehicle myVehicle={selectedMyVehicle} />
                    </div>
                  )}
                  <div className="checkout-section inner-box">
                    <OrderPaymentSummary
                      paymentTitle={"Sipariş Özeti"}
                      paymentTotalTitle={"Sipariş Toplamı"}
                      cartItems={selectedCartItems}
                      onDelete={(item) => {
                        setSelectedCarts((prev) =>
                          prev.filter((cart) => cart.product.id !== item.id),
                        );
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn" onClick={prevStep}>
                      Geri
                    </button>
                    <button className="btn" type="submit" onClick={nextStep}>
                      {currentStep >= steps.length - 1 ? "Tamamla" : "İleri"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
