import React from "react";
import "./Tag.scss";
interface IProps {
  tag: string;
  onDelete?: (tag: string) => void;
}

export default function Tag(props: IProps) {
  return (
    <div className="tags-container">
        <div className="tag">
          {props.tag}
          {
            props.onDelete &&
            <button
              className="delete-tag"
              onClick={() => props.onDelete && props.onDelete(props.tag)}
            >
              x
            </button>
          }
        </div>
    </div>
  );
}
